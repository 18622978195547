<ng-container [ngSwitch]="viewportBreakpoint">
    <ng-container *ngSwitchCase="'desktop'">
        <div class="help-page__panel">
            <div class="help-page__panel-inner">
                <div class="help-page__panel-logo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1442" height="420" class="help-page__panel-logo-svg" preserveAspectRatio="xMidYMid meet">
                        <use xlink:href="#svg-logo"></use>
                    </svg>
                </div>
                <nav class="help-page__nav">
                    <a class="help-page__nav-link" routerLink="/faq">{{ 'nav.items.faq' | translate }}</a>
                    <a class="help-page__nav-link" routerLink="/contact-us">{{ 'nav.items.contact_us' | translate }}</a>
                    <a class="help-page__nav-link" *ngIf="false" [class.help-page__nav-link_has-dot]="termsSession.hasDot" [routerLink]="termsSession.link">{{ 'nav.items.terms' | translate }}</a>
                </nav>
                <lang-switcher></lang-switcher>
                <a routerLink="/auth" class="help-page__login-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="help-page__login-link-icon">
                        <path style="fill: currentColor;" d="M10,1a9,9,0,1,0,9,9A9,9,0,0,0,10,1Zm0,16a6.9,6.9,0,0,1-4.47-1.62,7.93,7.93,0,0,1,8.94,0A6.9,6.9,0,0,1,10,17Zm0-5a9.94,9.94,0,0,0-5.83,1.88,7,7,0,1,1,11.66,0A9.94,9.94,0,0,0,10,12Zm0-1a3,3,0,1,1,3-3A3,3,0,0,1,10,11Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,10,7Z"></path>
                    </svg>
                    <span class="help-page__login-link-text">{{ 'nav.items.login' | translate }}</span>
                </a>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <app-bar></app-bar>
    </ng-container>
</ng-container>

<div class="help-page__content">
    <ng-content></ng-content>
</div>

<ng-container *ngIf="viewportBreakpoint === 'desktop'">
    <footer class="help-page__footer">
        <div class="help-page__footer-inner">
            <span class="help-page__footer-copyright">© 2021 TAPNPAY</span>
        </div>
    </footer>
</ng-container>
