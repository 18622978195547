<div class="google-map__container">
    <div class="google-map__map" #map></div>
    <div class="google-map__header">
        <div class="google-map__header-title">
            {{ title }}
        </div>
        <button type="button" class="google-map__header-close" (click)="onCloseClick()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
                <path style="fill: currentColor;" d="M14.364,0.222 L15.778,1.636 L9.414,8.000 L15.778,14.364 L14.364,15.778 L8.000,9.414 L1.636,15.778 L0.222,14.364 L6.586,8.000 L0.222,1.636 L1.636,0.222 L8.000,6.586 L14.364,0.222 Z"></path>
            </svg>
        </button>
    </div>
</div>
