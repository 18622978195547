<!-- Page Header -->
<ng-container [ngSwitch]="viewportBreakpoint">
    <page-panel *ngSwitchCase="'desktop'">
        <h1 class="page-panel__header page-panel__header_has-arrow"
            (click)="onGoBack()"
            [innerHTML]="'vehicles.page_header' | translate"
        ></h1>
        <div class="page-panel__controls">
            <button type="button" class="button button_regular button_light-blue" (click)="onAddVehicle()">
                <span class="button__caption">{{ 'vehicles.add_vehicle' | translate }}</span>
            </button>
        </div>
    </page-panel>
    <app-bar [showBackButton]="true" (onBack)="onGoBack()" *ngSwitchDefault>
        <button type="button" class="app-bar__button" (click)="onAddVehicle()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="button_icon">
                <path fill="currentColor" d="M12,6v6h0V6m1-1H11v6H5v2h6v6h2V13h6V11H13V5Z"/>
            </svg>
        </button>
    </app-bar>
</ng-container>

<!-- Page Content -->
<div class="vehicles__content" [ngSwitch]="listState">

    <div class="vehicles__loading" *ngSwitchCase="'loading'">
        <loader></loader>
    </div>

    <div class="vehicles__message" *ngSwitchCase="'empty'">
        {{ 'vehicles.no_vehicles' | translate }}
    </div>

    <div class="vehicles__message vehicles__message_error" *ngSwitchCase="'error'">
        {{ 'vehicles.vehicles_error' | translate }}
    </div>

    <ng-container *ngSwitchCase="'list'" [ngSwitch]="viewportBreakpoint">

        <div class="vehicles__table-wrap" *ngSwitchCase="'desktop'">
            <div class="test-card" *ngIf="listOfLicensePlates && listOfLicensePlates.length > 0">
                <app-lpns-list [listTitle]="'vehicles.plates_list_personal_title' | translate"
                               [lpnsList]="listOfLicensePlates"
                               (onLpnSelectedToUnregister)="onUnregisterClick($event)"
                ></app-lpns-list>
            </div>

            <ng-container *ngIf="listOfRentalLicensePlates && listOfRentalLicensePlates.length > 0">
                <div class="test-card">
                    <app-lpns-list [listTitle]="'vehicles.plates_list_rental_title' | translate"
                                   [isRental]="true"
                                   [lpnsList]="listOfRentalLicensePlates"
                                   (onLpnSelectedToUnregister)="onUnregisterClick($event)"
                                   (onLpnSelectedToRentalExtend)="onExtendLPNRentalPeriod($event)"
                    ></app-lpns-list>
                </div>
            </ng-container>

        </div>

        <ng-container *ngSwitchDefault>
            <h2 class="page-header">{{'vehicles.plates_list_personal_title' | translate}}</h2>
            <div class="vehicles__list">
                <div class="vehicles__list-item vehicles__list-item vehicles__list-item-base"
                     *ngFor="let licensePlate of listOfLicensePlates">
                    <div class="vehicles__list-item-primary">
                        <div class="vehicles__list-item-primary-item">
                            <span class="lpn-name">{{ licensePlate.lps }} {{ licensePlate.lpn }}</span>
                        </div>
                        <div class="vehicles__list-item-primary-item vehicles__list-item-primary-item_light">
                            <span class="lpn-card-value-label">{{ 'vehicles.date_th' | translate }}</span>
                            <span
                                class="lpn-card-value">{{ licensePlate.registered | datetime : 'display.datetime' }}</span>
                        </div>
                    </div>
                    <div class="vehicles__list-item-secondary">
                        <button type="button" class="button button_regular button_light-red"
                                (click)="onUnregisterClick(licensePlate)"
                        >
                            <span class="button__caption">{{ 'vehicles.unreg_button' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <h2 class="page-header" *ngIf="listOfRentalLicensePlates && listOfRentalLicensePlates.length > 0">
                {{'vehicles.plates_list_rental_title' | translate}}
            </h2>
            <div class="vehicles__list">
                <div class="vehicles__list-item vehicles__list-item-rental"
                     *ngFor="let licensePlate of listOfRentalLicensePlates">
                    <div class="vehicles__list-item-primary">
                        <div class="vehicles__list-item-primary-item">
                            <span class="lpn-name">{{ licensePlate.lps }} {{ licensePlate.lpn }}</span>
                        </div>
                        <div class="d-flex mb-16 w-100 space-between">
                            <div class="vehicles__list-item-primary-item vehicles__list-item-primary-item_light">
                                <span
                                    class="lpn-card-value-label">{{ 'vehicles.date_th' | translate }}</span>
                                <span class="lpn-card-value">
                                {{ licensePlate.registered | datetime : 'display.datetime' }}
                            </span>
                            </div>
                            <div class="vehicles__list-item-primary-item vehicles__list-item-primary-item_light">
                                <span
                                    class="lpn-card-value-label">{{ 'vehicles.rental_period_return_date_label' | translate}}</span>
                                <span class="lpn-card-value">
                                {{ licensePlate.end_date | datetime : 'display.datetime' }}
                            </span>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="!isLicensePlateExpired(licensePlate)">
                        <div class="d-flex space-between border-padding">
                            <div class="vehicles__list-item-secondary">
                                <button type="button" class="button button_regular button_light-red"
                                        (click)="onUnregisterClick(licensePlate)"
                                        [isDisabled]="isLicensePlateExpired(licensePlate)"
                                >
                                    <span class="button__caption">{{ 'vehicles.unreg_button' | translate }}</span>
                                </button>
                            </div>
                            <div class="vehicles__list-item-secondary">
                                <button type="button" class="button button_regular button_blue"
                                        (click)="onExtendLPNRentalPeriod(licensePlate)"
                                        [isDisabled]="isLicensePlateExpired(licensePlate)"
                                >
                                    <span
                                        class="button__caption">{{'vehicles.extend_period_btn_text' | translate}}</span>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>


        </ng-container>
    </ng-container>
</div>

<!-- Popups -->
<popup-custom class="vehicles__unreg-popup" *ngIf="activePopup === 'unregister-confirm'" [isDisabled]="isLoading">
    <popup-content>
        <span
            [innerHTML]="'vehicles.confirm_unreg' | translate : { lps: lpToUnregister.lps, lpn: lpToUnregister.lpn }"></span>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_light-blue" (click)="onUnregisterCancel()">
            <span class="button__caption">{{ 'vehicles.confirm_unreg_cancel' | translate }}</span>
        </button>
        <button type="button" class="button button_regular button_blue" (click)="onUnregisterConfirm()"
                [isProgress]="isLoading">
            <span class="button__caption">{{ 'vehicles.confirm_unreg_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="vehicles__add-popup"
              *ngIf="activePopup === 'add'"
              [isDisabled]="isLoading"
              (onClose)="onCloseAddPopup()"
>
    <popup-content>
        <div class="vehicles__add-popup-error"
             *ngIf="addPopupError"
             [innerHTML]="addPopupError.key | translate : addPopupError.data"
        ></div>
        <label class="vehicles__add-popup-label">
            <span class="vehicles__add-popup-label-text">{{ 'vehicles.add_popup_label' | translate }}</span>
            <input type="text" class="input input_regular vehicles__add-popup-input"
                   placeholder="TX 123456"
                   [(ngModel)]="newLpnName"
                   (ngModelChange)="validateLpn()"
            >
        </label>
        <div class="vehicles__add-popup-is_rental" *ngIf="this.accountPaymentModel === 'FLEET'">
            <checkbox [(ngModel)]="newLpnIsRentalCheckboxModel">
                {{ 'vehicles.is_rental_license_plate' | translate }}
            </checkbox>
        </div>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_light-blue" (click)="onCloseAddPopup()">
            <span class="button__caption">{{ 'vehicles.add_vehicle_cancel' | translate }}</span>
        </button>
        <button type="button" class="button button_regular button_blue"
                [isProgress]="isLoading"
                [isDisabled]="!isLpnValid"
                (click)="submitNewLPNForm()"
        >
            <span class="button__caption">{{ 'vehicles.add_vehicle_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="vehicles__error-popup" *ngIf="activePopup === 'error'">
    <popup-content>
        <span [innerHTML]="errorPopupMessage.key | translate : errorPopupMessage.data"></span>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_blue" (click)="hideErrorPopup()">
            <span class="button__caption">{{ 'vehicles.error_popup_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="vehicles__payment-popup" *ngIf="activePopup === 'payment'"
              [isDisabled]="paymentSubmittingBy !== null">
    <popup-content>
        <div [innerHTML]="payByMailConfirmMessageKey | translate : paymentResultMessageData"></div>
        <input type="text" class="input input_large vehicles__payment-popup-zip"
               *ngIf="paymentConfig.payment_method_type === 'DCB' && isPaymentZipRequired"
               [(ngModel)]="zipCode"
               [placeholder]="'profile.pbm.zip_code' | translate"
               (ngModelChange)="validatePaymentPopup()"
        >
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_light-blue"
                (click)="onSubmitPayment(false)"
                [isDisabled]="paymentSubmittingBy !== null"
                [isProgress]="paymentSubmittingBy === 'no'"
        >
            <span class="button__caption">{{ 'profile.pbm.button_no' | translate }}</span>
        </button>
        <button type="button" class="button button_regular button_blue"
                (click)="onSubmitPayment(true)"
                [isDisabled]="!isPaymentPopupValid || paymentSubmittingBy !== null"
                [isProgress]="paymentSubmittingBy === 'yes'"
        >
            <span class="button__caption">{{ 'profile.pbm.button_yes' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="vehicles__payment-result-popup" *ngIf="activePopup === 'payment-result'">
    <popup-content>
        <span [innerHTML]="paymentResultMessageKey | translate : paymentResultMessageData"></span>
    </popup-content>
    <popup-controls>
        <button type="button" class="button button_regular button_blue" (click)="hidePaymentResultMessage()">
            <span class="button__caption">{{ 'profile.pbm.button_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<!-- /// -->

<popup-custom class="vehicles__fleet-lpn-popup" *ngIf="activePopup === 'fleet-lpn'" [isDisabled]="isConfirmingFleet">
    <popup-content>
        <div class="vehicles__fleet-lpn-popup-header">
            {{ 'profile.fleet.header_message' | translate }}
        </div>
        <ng-container *ngFor="let plate of listOfPendingLPNs.plates">
            <app-lpn-card [pendingLPNsFee]="listOfPendingLPNs.fee"
                          [plate]="plate"
                          [selectModel]="isSelectedForPaymentPendingLPNByIdMap"
                          [datesModel]="mapOfDatesForRentalPendingLPNs[plate.id]"
                          [form]="pendingLPNsForm"
                          (onSelectedLPN)="recountTotalSumOfSelectedForPaymentPendingLPNs($event)">
            </app-lpn-card>
        </ng-container>
    </popup-content>
    <popup-controls>
        <button type="button" class="button button_regular button_blue"
                [isProgress]="isConfirmingFleet"
                [isDisabled]="isConfirmingFleet"
                (click)="onConfirmFleetPayment()"
        >
            <span *ngIf="selectedForPaymentPendingLPNsCount < 1" class="button__caption">
                {{ 'vehicles.add_vehicle_cancel' | translate }}
            </span>
            <span *ngIf="selectedForPaymentPendingLPNsCount > 0" class="button__caption">
                {{ 'profile.fleet.pay_button' | translate : {amount: (activePendingLPNsTotal | currency : 'USD')} }}
            </span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="vehicles__fleet-lpn-zip-popup" *ngIf="activePopup === 'fleet-lpn-zip'"
              [isDisabled]="isFleetZipSubmitting">
    <popup-content>
        <input type="text" class="input input_large vehicles__fleet-lpn-zip-popup-zip"
               [(ngModel)]="zipCode"
               [placeholder]="'profile.fleet.zip_code' | translate"
               (ngModelChange)="validateFleetZipPopup()"
        >
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_blue"
                (click)="submitFleetZipPayment()"
                [isDisabled]="!isFleetZipValid"
                [isProgress]="isFleetZipSubmitting"
        >
            <span class="button__caption">{{ 'profile.fleet.zip_pay' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="vehicles__fleet-lpn-wallet-popup"
              *ngIf="activePopup === 'fleet-wallet-payment-confirm'"
              [isDisabled]="isFleetWalletSubmitting"
>
    <popup-content>
        <span
            [innerHTML]="'profile.fleet.wallet_confirm_message' | translate : { amount: walletFleetPaymentAttrs.amountFormatted, wallet: walletFleetPaymentAttrs.wallet }"></span>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_blue" [isProgress]="isFleetWalletSubmitting"
                (click)="onConfirmFleetWalletPayment()">
            <span class="button__caption">{{ 'profile.fleet.wallet_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<!-- /// -->

<popup-custom class="extend-rental-popup"
              *ngIf="activePopup === 'extend-rental-lpn-period'">
    <popup-content>
        <h3 class="mb-16 popup-title">
            {{'vehicles.rental_period_extend_modal_title' | translate}}
            <br>
            {{selectedLPNForExtend.lps}} {{selectedLPNForExtend.lpn}}
        </h3>
        <div class="rental-car-period-select" [formGroup]="extendLPNPeriodForm">
            <div class="mb-16">
                <label class="period-select mb-8 d-block">
                    <span class="period-select__label">{{ 'dashboard.period_select_date' | translate }}</span>
                    <datepicker class="period-select__picker"
                                formControlName="end_date"
                                [minDate]="selectedLPNForExtend.end_date"
                                [format]="'input.date'"
                                [type]="'iso'"></datepicker>
                </label>
                <app-validation-errors class="d-block"
                                       messageTextAlign="left"
                                       [requiredErrorMessage]="'vehicles.rental_period_extend.validation_error.date_required'"
                                       [validatedFormControl]="getControl('end_date')">
                </app-validation-errors>
            </div>
            <div class="mb-16">
                <label class="period-select mb-8 d-block">
                    <span class="period-select__label">{{ 'dashboard.period_select_time' | translate }}</span>
                    <datepicker class="period-select__picker"
                                formControlName="end_time"
                                [isShowDatePicker]="false"
                                [format]="'hh:mm'"
                                [type]="'iso'"></datepicker>
                </label>
                <app-validation-errors class="d-block"
                                       messageTextAlign="left"
                                       [requiredErrorMessage]="'vehicles.rental_period_extend.validation_error.time_required'"
                                       [validatedFormControl]="getControl('end_time')">
                </app-validation-errors>
            </div>
        </div>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button"
                class="button button_regular button_light-blue"
                (click)="onCancelExtendRentalPeriodPopup()">
            <span class="button__caption">{{ 'vehicles.add_vehicle_cancel' | translate }}</span>
        </button>
        <button type="button"
                class="button button_regular button_blue"
                (click)="onSubmitExtendRentalPeriodPopup()">
            <span class="button__caption">{{ 'profile.deactivate_account_result_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<payment-method
    *ngIf="activePopup === 'payment-method'"
    [mode]="'change'"
    [isSilentInit]="true"
    (onDone)="onPaymentMethodSelected($event)"
></payment-method>
