import { LOCALES } from './common';

export const CONFIG = {
    isProduction: true,
    isDebug: true,
    env: 'demo',
    locales: LOCALES,
    server: 'https://demo-app.tapnpaydemo.com',
    stripe: {
        apiKey: 'pk_test_51GqLxbKNGXurSY8vx3wil6g6eoFhdCcHJVaKgkjUFFFZQAbs1XInC2L9xKEL9F6lEPGUsCY9hAY08zsxcTqU7PCQ00H5EXF8G1'
    },
    tidio: {
        apiKey: 'dfluya5i467z0oym3xi9lcopwjxxsyuq'
    },
    google: {
        apiKey: 'AIzaSyDSDXVnwnr3PuEQh6DnkYyrvEjF52LHpXs'
    },
};
