<div class="lpn-card">
    <div class="lpn-card__row">
        <div class="lpn-card__check">
            <checkbox
                [disabled]="isDisabledToSelect"
                [(ngModel)]="selectModel[plate.id]"
                (ngModelChange)="emmitSelectedLPN(plate)"
            ></checkbox>
        </div>
        <div class="lpn-card__title">
            {{ plate.lps }} {{ plate.lpn }}
        </div>
        <div class="lpn-card__fee">
            {{ pendingLPNsFee | currency : 'USD' }}
        </div>
    </div>
    <div class="rental-car-period-select"
         [@inOutAnimation]
         *ngIf="plate.rental && selectModel[plate.id]"
         [formGroup]="form">
        <div [formGroupName]="plate.id">
            <label class="period-select">
                <span class="period-select__label">{{ 'dashboard.period_select_date' | translate }}</span>
                <datepicker class="period-select__picker"
                            [isBlockToSelectDayBeforeToday]="true"
                            formControlName="endDate"
                            [format]="'input.date'"
                            [type]="'iso'"></datepicker>
            </label>
            <app-validation-errors
                [requiredErrorMessage]="'dashboard.period_select.validation_errors.create_date_required'"
                [validatedFormControl]="getField('endDate', plate.id)"></app-validation-errors>

            <label class="period-select">
                <span class="period-select__label">{{ 'dashboard.period_select_time' | translate }}</span>
                <datepicker class="period-select__picker"
                            formControlName="endTime"
                            [isShowDatePicker]="false"
                            [format]="'hh:mm'"
                            [type]="'iso'"></datepicker>
            </label>
            <app-validation-errors
                [requiredErrorMessage]="'dashboard.period_select.validation_errors.create_time_required'"
                [validatedFormControl]="getField('endTime', plate.id)"></app-validation-errors>
        </div>
    </div>
</div>


