<ng-container [ngSwitch]="activePopup">
    <popup-custom class="payment-method__method-popup"
        [isDisabled]="isMethodPopupSubmitting || isCheckingDCBEligibility || isVenmoUpdating"
        *ngSwitchCase="'method'"
    >
        <popup-content [ngSwitch]="methodPopupState">
            <div class="payment-method__method-popup-loading" *ngSwitchCase="'init'">
                <loader></loader>
            </div>
            <div class="payment-method__method-popup-fatal" *ngSwitchCase="'error'">
                {{ 'stripe.error.common.generic' | translate }}
            </div>
            <ng-container *ngSwitchCase="'ready'">
                <div class="payment-method__method-popup-header" *ngIf="!!methodHeader">
                    {{ methodHeader.key | translate : methodHeader.data }}
                </div>
                <div class="payment-method__method-popup-error" *ngIf="!!methodPopupError">
                    {{ methodPopupError.key | translate : methodPopupError.data }}
                </div>
                <div class="payment-method__method-popup-methods">
                    <!-- BEGIN: DCB -->
                    <div class="payment-method__method-popup-method"
                         *ngIf="paymentOptions.DCB.enabled"
                         [ngClass]="{
                             'payment-method__method-popup-method_active': selectedPaymentMethod && selectedPaymentMethod.type === 'DCB',
                             'payment-method__method-popup-method_unknown': paymentOptions.DCB.status === 'UNKNOWN',
                             'payment-method__method-popup-method_ineligible': paymentOptions.DCB.status === 'INELIGIBLE'
                         }"
                         [title]="paymentOptions.DCB.status === 'UNKNOWN' ? ('payment_method.eligibility_check.check_tooltip' | translate) : ''"
                         (click)="onDCBMethodClick()"
                    >
                        <div class="payment-method__method-popup-method-text">
                            {{ 'payment_method.options.dcb' | translate }}
                            <ng-container [ngSwitch]="paymentOptions.DCB.status">
                                <div class="payment-method__method-popup-method-subtext" *ngSwitchCase="'UNKNOWN'">
                                    {{ 'payment_method.eligibility_check.needs_check' | translate }}
                                </div>
                                <div class="payment-method__method-popup-method-subtext" *ngSwitchCase="'INELIGIBLE'">
                                    {{ 'payment_method.eligibility_check.ineligible' | translate }}
                                </div>
                            </ng-container>
                        </div>
                        <ng-container [ngSwitch]="isCheckingDCBEligibility">
                            <div class="payment-method__method-popup-method-logos" *ngSwitchCase="false" [ngSwitch]="paymentOptions.DCB.carrier">
                                <svg xmlns="http://www.w3.org/2000/svg" width="658.2" height="146" viewBox="0 0 658.2 146" class="payment-method__method-popup-method-logo payment-method__method-popup-method-logo_verizon" *ngSwitchCase="'VERIZON'">
                                    <polygon points="643.7 0 607.8 76.8 594.3 47.7 579.7 47.7 601.9 95.3 613.7 95.3 658.2 0 643.7 0" fill="#d52b1e"/>
                                    <path d="M489.7,142.6h28.9V89.7c0-12.1,7-20.6,17.4-20.6,10,0,15.2,7,15.2,17.1v56.4h28.9V80.7c0-21-12.6-35.8-33-35.8-13,0-22.1,5.6-28.9,15.8h-.6v-13h-28l.1,94.9ZM432.9,45.1c-30.2,0-50.4,21.7-50.4,50.3,0,28.4,20.2,50.3,50.4,50.3s50.4-21.9,50.4-50.3C483.4,66.8,463.1,45.1,432.9,45.1Zm-.2,79.2c-13.7,0-21-11.5-21-28.9,0-17.6,7.2-28.9,21-28.9S454,77.8,454,95.4C454.1,112.8,446.5,124.3,432.7,124.3ZM300.1,142.6h81.2V119.8h-46v-.6l44-49.3V47.6H300.1V70.5h44.5v.6l-44.5,49.7v21.8Zm-37.1,0h29.1V47.7H263Zm-67.5,0h29V99c0-19.8,11.9-28.6,30-26.1h.6v-25c-1.5-.6-3.2-.7-5.9-.7-11.3,0-18.9,5.2-25.4,16.3h-.6V47.7H195.5v94.9Zm-53.2-18.2c-12.8,0-20.6-8.3-22.1-21.1h68.4c.2-20.4-5.2-36.7-16.5-46.9-8-7.4-18.5-11.5-31.9-11.5-28.6,0-48.4,21.7-48.4,50.1,0,28.6,18.9,50.4,50.3,50.4,11.9,0,21.3-3.2,29.1-8.5,8.3-5.7,14.3-14.1,15.9-22.4H159.3C156.6,120.7,150.8,124.4,142.3,124.4Zm-1.5-58.8c10.2,0,17.2,7.6,18.4,18.7H120.4C122.7,73.1,128.8,65.6,140.8,65.6ZM34,142.6H64.4l33-94.9H68.3l-18.5,61h-.4l-18.5-61H1ZM263,13.9h29.1V39.7H263Z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1000.27" height="411.08" viewBox="0 0 1000.27 411.08" class="payment-method__method-popup-method-logo payment-method__method-popup-method-logo_att" *ngSwitchCase="'ATT'">
                                    <path d="M925.45,287.92a4.32,4.32,0,0,1-4.52-4.52V150.87h-45a4.31,4.31,0,0,1-4.52-4.52V128.07a4.32,4.32,0,0,1,4.52-4.53H995.75a4.32,4.32,0,0,1,4.52,4.53v18.28a4.31,4.31,0,0,1-4.52,4.52h-45V283.4a4.32,4.32,0,0,1-4.52,4.52H925.45M580.58,221.76,557,154l-23.84,67.8ZM633,282.57a3.76,3.76,0,0,1-3.49,5.35H608.14c-3.09,0-4.94-1.43-6-4.32l-12.12-34.92H523.66L511.52,283.6c-1,2.89-2.88,4.32-6,4.32H485.43a3.84,3.84,0,0,1-3.5-5.35l55.7-154.91c1-2.88,2.88-4.11,6-4.11h27.55c3.08,0,5.14,1.23,6.17,4.11L633,282.57m159.67-16.84c13.14,0,22-6.36,29.18-17.26l-33.29-35.75c-12.75,7.2-21,14.38-21,28.77,0,14.18,11.51,24.24,25.09,24.24m9.24-120.41c-10.68,0-16.85,6.79-16.85,15.83,0,7,3.69,13.15,12.12,22.19,14.6-8.43,20.77-13.56,20.77-22.6,0-8.43-5.34-15.42-16-15.42M893,282.17c2.67,2.88,1,5.75-2.27,5.75H864.6c-3.49,0-5.34-.82-7.6-3.5l-15.62-17.25c-10.48,14-25.09,24.45-49.34,24.45-30,0-53.65-18.08-53.65-49.1,0-23.84,12.75-36.58,32.08-47.26-9.47-10.89-13.78-22.4-13.78-32.46,0-25.49,17.89-43,44.81-43,27.54,0,44.4,16.24,44.4,40.27,0,20.55-14.8,32-30.42,40.68l23,24.87,13-22.61c1.64-2.67,3.49-3.7,6.78-3.7h19.93c3.3,0,5.15,2.27,3.1,5.76l-23,39.44L893,282.17m-205.13,5.75a4.32,4.32,0,0,0,4.53-4.52V150.87h45a4.31,4.31,0,0,0,4.52-4.52V128.07a4.32,4.32,0,0,0-4.52-4.53H617.55a4.32,4.32,0,0,0-4.52,4.53v18.28a4.31,4.31,0,0,0,4.52,4.52h45V283.4a4.32,4.32,0,0,0,4.52,4.52Z" fill="#000"/>
                                    <path d="M79.53,367.86a205.55,205.55,0,0,0,261.53-7.75c.44-.38.23-.64-.21-.38-16.21,10.83-62.44,34.47-135.24,34.47-63.26,0-103.24-14.11-125.81-26.71-.44-.22-.6.11-.27.37m140,10.51c50.6,0,106.2-13.79,139.45-41.1,9.1-7.43,17.77-17.34,25.53-30.64A197.32,197.32,0,0,0,397,280.94c.16-.44-.11-.65-.44-.16C365.59,326.29,276,354.67,183.58,354.67c-65.36,0-135.68-20.9-163.21-60.81-.28-.37-.55-.21-.38.22,25.65,54.51,103.46,84.29,199.58,84.29m-55.28-90.44c-105.24,0-154.86-49-163.86-82.46-.11-.48-.43-.37-.43.06C0,216.79,1.13,231.32,3.07,241,4,245.66,7.81,253,13.41,258.9c25.49,26.57,89,63.78,199,63.78,149.9,0,184.18-49.93,191.18-66.35,5-11.75,7.6-33,7.6-50.8,0-4.31-.11-7.76-.28-11.14,0-.55-.31-.59-.42-.06C403,234.51,275,287.93,164.29,287.93M19.82,117.36c-6,12-12.71,32.16-14.7,42.61-.87,4.47-.5,6.62,1.07,10,12.61,26.76,76.41,69.58,225.23,69.58,90.79,0,161.32-22.3,172.75-63,2.1-7.49,2.21-15.4-.49-26.06-3-11.91-8.68-25.8-13.46-35.56-.16-.31-.44-.27-.38.11,1.77,53.38-147.1,87.79-222.22,87.79-81.36,0-149.25-32.42-149.25-73.35a50.25,50.25,0,0,1,1.83-12c.11-.38-.21-.44-.38-.11M341.4,51.81a8.37,8.37,0,0,1,1.29,4.75c0,22.83-69.89,63.23-181.15,63.23-81.74,0-97-30.32-97-49.61,0-6.89,2.65-14,8.47-21.11.32-.43.05-.59-.32-.28A206.15,206.15,0,0,0,43.7,78.85c-4.09,5.17-6.63,9.75-6.63,12.5,0,40,100.22,68.94,193.92,68.94,99.85,0,144.41-32.59,144.41-61.24,0-10.23-4-16.21-14.18-27.79a243.08,243.08,0,0,0-19.5-19.72c-.32-.26-.55,0-.32.27M310.79,29A205.4,205.4,0,0,0,99,29.84C89.71,35.55,84.48,40.12,84.48,46c0,17.34,40.52,36,112.4,36,71.13,0,126.3-20.42,126.3-40.07,0-4.69-4.1-8-12.39-12.93" fill="#00a8e0"/>
                                </svg>
                            </div>
                            <button type="button" class="payment-method__method-popup-method-check-icon" *ngSwitchCase="true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                    <path fill="currentColor" d="M4,14H6A8,8,0,0,1,19.29,8H16v2h7V3H21V6.86A10,10,0,0,0,4,14Zm8,4v2H8.72A8,8,0,0,0,22,14h2A10,10,0,0,1,7,21.14V25H5V18Z"/>
                                </svg>
                            </button>
                        </ng-container>
                        <div class="payment-method__method-popup-method-checkbox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <polygon fill="currentColor" points="9 14.07 4.93 10 6.34 8.59 9 11.24 13.66 6.59 15.07 8 9 14.07"/>
                            </svg>
                        </div>
                    </div>

                    <!-- END: DCB -->
                    <!-- BEGIN: PayPal -->

                    <div class="payment-method__method-popup-method"
                        *ngIf="paymentOptions.PAYPAL.enabled"
                        [class.payment-method__method-popup-method_active]="selectedPaymentMethod && selectedPaymentMethod.type === 'PAYPAL'"
                        (click)="onMethodClick('PAYPAL')"
                    >
                        <div class="payment-method__method-popup-method-text">PayPal</div>
                        <div class="payment-method__method-popup-method-logos">
                            <svg xmlns="http://www.w3.org/2000/svg" width="124" height="33" viewBox="0 0 124 33" class="payment-method__method-popup-method-logo payment-method__method-popup-method-logo_paypal">
                                <path d="M46.21,6.75H39.37a1,1,0,0,0-.94.8L35.67,25.09a.57.57,0,0,0,.56.66H39.5a.94.94,0,0,0,.93-.81l.75-4.73a1,1,0,0,1,.94-.8h2.16c4.51,0,7.11-2.18,7.79-6.5A5.29,5.29,0,0,0,51.2,8.5C50.22,7.35,48.5,6.75,46.21,6.75Zm.79,6.4c-.37,2.46-2.25,2.46-4.06,2.46h-1L42.63,11a.57.57,0,0,1,.56-.48h.48c1.23,0,2.4,0,3,.71A2.25,2.25,0,0,1,47,13.15Z" style="fill: #253b80"/>
                                <path d="M66.65,13.07H63.38a.57.57,0,0,0-.56.49l-.15.91-.23-.33c-.71-1-2.29-1.37-3.87-1.37a7.53,7.53,0,0,0-7.31,6.58,6.17,6.17,0,0,0,1.22,5,5.13,5.13,0,0,0,4.13,1.67,6.28,6.28,0,0,0,4.53-1.87l-.15.91a.58.58,0,0,0,.57.66h3a.94.94,0,0,0,.93-.81l1.77-11.21A.57.57,0,0,0,66.65,13.07Zm-4.56,6.38a3.66,3.66,0,0,1-3.7,3.13,2.77,2.77,0,0,1-2.2-.89,2.81,2.81,0,0,1-.51-2.3,3.68,3.68,0,0,1,3.67-3.15,2.75,2.75,0,0,1,2.18.89A2.85,2.85,0,0,1,62.09,19.45Z" style="fill: #253b80"/>
                                <path d="M84.1,13.07H80.81a1,1,0,0,0-.79.42l-4.54,6.69-1.93-6.43a1,1,0,0,0-.91-.68H69.41a.57.57,0,0,0-.54.76l3.62,10.64-3.41,4.81a.57.57,0,0,0,.47.9h3.29a1,1,0,0,0,.78-.41L84.56,14A.57.57,0,0,0,84.1,13.07Z" style="fill: #253b80"/>
                                <path d="M95,6.75H88.15a1,1,0,0,0-.94.8L84.45,25.09a.57.57,0,0,0,.56.66h3.51a.67.67,0,0,0,.66-.57l.78-5a1,1,0,0,1,.94-.8h2.16c4.51,0,7.11-2.18,7.79-6.5A5.26,5.26,0,0,0,100,8.5C99,7.35,97.28,6.75,95,6.75Zm.79,6.4c-.37,2.46-2.25,2.46-4.06,2.46h-1L91.41,11a.58.58,0,0,1,.56-.48h.48c1.23,0,2.4,0,3,.71A2.25,2.25,0,0,1,95.78,13.15Z" style="fill: #179bd7"/>
                                <path d="M115.43,13.07h-3.27a.57.57,0,0,0-.56.49l-.15.91-.23-.33c-.71-1-2.29-1.37-3.86-1.37a7.53,7.53,0,0,0-7.31,6.58,6.14,6.14,0,0,0,1.22,5,5.11,5.11,0,0,0,4.12,1.67,6.25,6.25,0,0,0,4.53-1.87l-.14.91a.57.57,0,0,0,.56.66h3a1,1,0,0,0,.94-.81L116,13.73A.58.58,0,0,0,115.43,13.07Zm-4.56,6.38a3.65,3.65,0,0,1-3.7,3.13,2.74,2.74,0,0,1-2.19-.89,2.77,2.77,0,0,1-.52-2.3,3.68,3.68,0,0,1,3.67-3.15,2.79,2.79,0,0,1,2.19.89A2.84,2.84,0,0,1,110.87,19.45Z" style="fill: #179bd7"/>
                                <path d="M119.29,7.23l-2.8,17.86a.57.57,0,0,0,.56.66h2.82a1,1,0,0,0,.94-.81l2.77-17.53a.57.57,0,0,0-.56-.66h-3.16A.58.58,0,0,0,119.29,7.23Z" style="fill: #179bd7"/>
                                <path d="M7.27,29.15l.52-3.32-1.17,0H1.06L4.93,1.29A.35.35,0,0,1,5,1.1.32.32,0,0,1,5.24,1h9.38C17.74,1,19.88,1.67,21,3a4.44,4.44,0,0,1,1,1.92,7,7,0,0,1,0,2.64l0,.08v.68l.53.29a3.83,3.83,0,0,1,1.07.82,3.73,3.73,0,0,1,.86,1.93,8.37,8.37,0,0,1-.12,2.82,9.78,9.78,0,0,1-1.16,3.18,6.48,6.48,0,0,1-1.82,2,7.49,7.49,0,0,1-2.46,1.11,12.52,12.52,0,0,1-3.07.35h-.73a2.22,2.22,0,0,0-1.43.53A2.18,2.18,0,0,0,13,22.62l-.06.3L12,28.78l0,.21a.18.18,0,0,1-.06.13.17.17,0,0,1-.1,0Z" style="fill: #253b80"/>
                                <path d="M23.05,7.67h0c0,.18-.06.36-.1.55-1.23,6.35-5.47,8.54-10.87,8.54H9.33A1.33,1.33,0,0,0,8,17.89H8L6.6,26.83l-.4,2.53a.7.7,0,0,0,.69.82h4.88a1.17,1.17,0,0,0,1.16-1l.05-.25.92-5.83.06-.32a1.17,1.17,0,0,1,1.16-1h.73c4.73,0,8.43-1.92,9.51-7.47.45-2.32.22-4.26-1-5.62A4.73,4.73,0,0,0,23.05,7.67Z" style="fill: #179bd7"/>
                                <path d="M21.75,7.15,21.17,7l-.62-.12a15.28,15.28,0,0,0-2.43-.17H10.77a1.18,1.18,0,0,0-.5.11,1.22,1.22,0,0,0-.66.88L8.05,17.6l0,.29a1.33,1.33,0,0,1,1.32-1.13h2.75c5.4,0,9.64-2.19,10.87-8.54,0-.19.07-.37.1-.55a5.9,5.9,0,0,0-1-.43Z" style="fill: #222d65"/>
                                <path d="M9.61,7.7a1.21,1.21,0,0,1,.66-.88,1.18,1.18,0,0,1,.5-.11h7.35a15.22,15.22,0,0,1,2.43.18l.62.11.58.15.28.09a5.9,5.9,0,0,1,1,.43c.37-2.35,0-3.95-1.27-5.39C20.38.68,17.85,0,14.62,0H5.24A1.33,1.33,0,0,0,3.92,1.13L0,25.9a.8.8,0,0,0,.8.93H6.6L8.05,17.6Z" style="fill: #253b80"/>
                            </svg>
                        </div>
                        <div class="payment-method__method-popup-method-checkbox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <polygon fill="currentColor" points="9 14.07 4.93 10 6.34 8.59 9 11.24 13.66 6.59 15.07 8 9 14.07"/>
                            </svg>
                        </div>
                    </div>

                    <!-- END: PayPal -->
                    <!-- BEGIN: Venmo -->

                    <div class="payment-method__method-popup-method"
                        *ngIf="paymentOptions.VENMO.enabled"
                        [class.payment-method__method-popup-method_active]="selectedPaymentMethod && selectedPaymentMethod.type === 'VENMO'"
                        (click)="onMethodClick('VENMO')"
                    >
                        <div class="payment-method__method-popup-method-text" [ngSwitch]="!!paymentOptions.VENMO.description">
                            <ng-container *ngSwitchCase="true">
                                Venmo: {{ paymentOptions.VENMO.description }}
                            </ng-container>
                            <ng-container *ngSwitchCase="false">
                                Venmo
                            </ng-container>
                        </div>
                        <ng-container [ngSwitch]="!!paymentOptions.VENMO.payment_method_token">
                            <div class="payment-method__method-popup-method-logos" *ngSwitchCase="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="32" viewBox="0 0 48 32" class="payment-method__method-popup-method-logo payment-method__method-popup-method-logo_venmo">
                                    <path d="M44.8,0H3.2A3.21,3.21,0,0,0,0,3.2V28.8A3.2,3.2,0,0,0,3.2,32H44.8A3.2,3.2,0,0,0,48,28.8V3.2A3.21,3.21,0,0,0,44.8,0ZM26.36,25.6H19L16,7.45l6.48-.63,1.57,12.92c1.46-2.44,3.28-6.28,3.28-8.9a7.36,7.36,0,0,0-.62-3.21l5.9-1.23a7.16,7.16,0,0,1,1,3.84C33.6,15,29.6,21.24,26.36,25.6Z" style="fill: #3d95ce;"/>
                                </svg>
                            </div>
                            <ng-container *ngSwitchCase="true" [ngSwitch]="paymentConfig.payment_method_type === 'VENMO'">
                                <button class="payment-method__method-popup-method-delete payment-method__method-popup-method-delete_venmo"
                                    *ngSwitchCase="false"
                                    [title]="'Detach Venmo account'"
                                    (click)="onDetachVenmo($event)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <polygon fill="currentColor" points="15.95 14.54 14.54 15.95 10 11.41 5.46 15.95 4.05 14.54 8.59 10 4.05 5.46 5.46 4.05 10 8.59 14.54 4.05 15.95 5.46 11.41 10 15.95 14.54"/>
                                    </svg>
                                </button>
                                <button class="payment-method__method-popup-method-delete payment-method__method-popup-method-delete_venmo"
                                    *ngSwitchCase="true"
                                    [class.payment-method__method-popup-method-delete_spinning]="isVenmoUpdating"
                                    [title]="'Attach another Venmo account'"
                                    (click)="onReplaceVenmo($event)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                        <path fill="currentColor" d="M4,14H6A8,8,0,0,1,19.29,8H16v2h7V3H21V6.86A10,10,0,0,0,4,14Zm8,4v2H8.72A8,8,0,0,0,22,14h2A10,10,0,0,1,7,21.14V25H5V18Z"/>
                                    </svg>
                                </button>
                            </ng-container>
                        </ng-container>
                        <div class="payment-method__method-popup-method-checkbox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <polygon fill="currentColor" points="9 14.07 4.93 10 6.34 8.59 9 11.24 13.66 6.59 15.07 8 9 14.07"/>
                            </svg>
                        </div>
                    </div>

                    <!-- END: Venmo -->
                    <!-- BEGIN: wallets -->

                    <!-- BEGIN: Google Pay -->
                    <div class="payment-method__method-popup-method"
                        *ngIf="(paymentOptions.WALLET.enabled && wallets && wallets.GOOGLEPAY) || paymentConfig.payment_method_type === 'GOOGLEPAY'"
                        [ngClass]="{
                            'payment-method__method-popup-method_active': selectedPaymentMethod && selectedPaymentMethod.type === 'GOOGLEPAY',
                            'payment-method__method-popup-method_unavailable': !paymentOptions.WALLET.enabled || !wallets || !wallets.GOOGLEPAY
                        }"
                        (click)="onMethodClick('GOOGLEPAY')"
                    >
                        <div class="payment-method__method-popup-method-text">
                            Google Pay
                            <div class="payment-method__method-popup-method-subtext" *ngIf="!paymentOptions.WALLET.enabled || !wallets || !wallets.GOOGLEPAY">
                                {{ 'payment_method.method_unavailable' | translate }}
                            </div>
                        </div>
                        <div class="payment-method__method-popup-method-logos">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="22" viewBox="0 0 50 22" class="payment-method__method-popup-method-logo payment-method__method-popup-method-logo_google-pay">
                                <path d="M24.64,10.78v5.83H22.88V2.24h4.9a4.36,4.36,0,0,1,3.13,1.25,4.16,4.16,0,0,1,.18,5.89l-.18.18a4.44,4.44,0,0,1-3.13,1.22Zm0-6.78V9h3.13a2.41,2.41,0,0,0,1.81-.74,2.45,2.45,0,0,0,0-3.47h0a2.4,2.4,0,0,0-1.81-.76ZM36.45,6.45a4.55,4.55,0,0,1,3.22,1.1,3.84,3.84,0,0,1,1.19,3v6.06H39.09V15.25H39A3.51,3.51,0,0,1,36,16.92a3.92,3.92,0,0,1-2.72-1,3.13,3.13,0,0,1-1.09-2.41,2.89,2.89,0,0,1,1.15-2.43,5,5,0,0,1,3.13-.91,5.28,5.28,0,0,1,2.7.61v-.42a2.13,2.13,0,0,0-.76-1.64,2.67,2.67,0,0,0-1.78-.68,2.8,2.8,0,0,0-2.44,1.32l-1.56-1A4.56,4.56,0,0,1,36.45,6.45Zm-2.39,7.14a1.51,1.51,0,0,0,.61,1.21,2.16,2.16,0,0,0,1.44.48,2.94,2.94,0,0,0,2.08-.86,2.65,2.65,0,0,0,.9-2,3.74,3.74,0,0,0-2.4-.69,3.14,3.14,0,0,0-1.88.55A1.6,1.6,0,0,0,34.06,13.59ZM51,6.78,44.75,20.94H42.92l2.3-5-4.06-9.2h2l2.92,7.06h0L49,6.78Z" fill="#5f6368"/>
                                <path d="M17.19,9.53a10,10,0,0,0-.14-1.64H9.24V11h4.47a3.85,3.85,0,0,1-1.66,2.51v2.06h2.67A8.11,8.11,0,0,0,17.19,9.53Z" fill="#4285f4"/>
                                <path d="M9.25,17.61a7.92,7.92,0,0,0,5.49-2l-2.67-2.06A5,5,0,0,1,5.14,12a5.33,5.33,0,0,1-.51-1.08H1.88v2.13A8.27,8.27,0,0,0,9.25,17.61Z" fill="#34a853"/>
                                <path d="M4.63,10.92a4.9,4.9,0,0,1,0-3.12V5.64H1.88a8.26,8.26,0,0,0,0,7.41Z" fill="#fbbc04"/>
                                <path d="M9.25,4.36A4.44,4.44,0,0,1,12.38,5.6h0l2.4-2.4A7.81,7.81,0,0,0,9.25,1.06,8.24,8.24,0,0,0,1.88,5.61L4.63,7.74A4.93,4.93,0,0,1,9.25,4.36Z" fill="#ea4335"/>
                            </svg>
                        </div>
                        <div class="payment-method__method-popup-method-checkbox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <polygon fill="currentColor" points="9 14.07 4.93 10 6.34 8.59 9 11.24 13.66 6.59 15.07 8 9 14.07"/>
                            </svg>
                        </div>
                    </div>
                    <!-- END: Google Pay -->
                    <!-- BEGIN: Apple Pay -->
                    <div class="payment-method__method-popup-method"
                        *ngIf="(paymentOptions.WALLET.enabled && wallets && wallets.APPLEPAY) || paymentConfig.payment_method_type === 'APPLEPAY'"
                        [ngClass]="{
                           'payment-method__method-popup-method_active': selectedPaymentMethod && selectedPaymentMethod.type === 'APPLEPAY',
                           'payment-method__method-popup-method_unavailable': !paymentOptions.WALLET.enabled || !wallets || !wallets.APPLEPAY
                        }"
                        (click)="onMethodClick('APPLEPAY')"
                    >
                        <div class="payment-method__method-popup-method-text">
                            Apple Pay
                            <div class="payment-method__method-popup-method-subtext" *ngIf="!paymentOptions.WALLET.enabled || !wallets || !wallets.APPLEPAY">
                                {{ 'payment_method.method_unavailable' | translate }}
                            </div>
                        </div>
                        <div class="payment-method__method-popup-method-logos">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="22" viewBox="0 0 50 22" class="payment-method__method-popup-method-logo payment-method__method-popup-method-logo_apple-pay">
                                <path fill="#000" d="M9.56,3.57a3,3,0,0,1-2.4,1.14A3.41,3.41,0,0,1,8,2.23,3.63,3.63,0,0,1,10.4,1a3.54,3.54,0,0,1-.84,2.57m.82,1.34c-1.32-.08-2.45.74-3,.74s-1.63-.74-2.68-.74A3.92,3.92,0,0,0,1.35,7C-.08,9.41,1,13.05,2.35,15c.67,1,1.52,2.08,2.54,2s1.42-.66,2.63-.66,1.52.66,2.65.64,1.79-1,2.46-2a8.17,8.17,0,0,0,1.11-2.28,3.56,3.56,0,0,1-2.16-3.25,3.64,3.64,0,0,1,1.75-3.12,3.73,3.73,0,0,0-2.95-1.52M18,2.14V17h2.29V11.89h3.2a4.7,4.7,0,0,0,4.94-4.45,3.38,3.38,0,0,0,0-.45A4.64,4.64,0,0,0,24,2.13H18Zm2.29,1.95H23a2.76,2.76,0,0,1,3.11,2.37,2.63,2.63,0,0,1,0,.56,2.77,2.77,0,0,1-2.59,3,2.65,2.65,0,0,1-.56,0H20.31Zm12.32,13A3.83,3.83,0,0,0,36,15.19h0V17h2.12V9.58c0-2.15-1.71-3.52-4.35-3.52s-4.13,1.39-4.21,3.32h2.09a2,2,0,0,1,2.17-1.52c1.4,0,2.19.65,2.19,1.87v.82l-2.86.17C30.47,10.87,29,12,29,13.87a3.26,3.26,0,0,0,3.28,3.22Zm.61-1.75c-1.21,0-2-.6-2-1.52s.76-1.52,2.19-1.52L36,12.13V13a2.5,2.5,0,0,1-2.64,2.34h0ZM41,21c2.24,0,3.3-.85,4.22-3.45L49.33,6.2H47L44.28,15h0L41.53,6.2h-2.4L43,17l-.21.67a1.8,1.8,0,0,1-1.93,1.52,4,4,0,0,1-.69,0V21a4.42,4.42,0,0,0,.89,0Z"/>
                            </svg>
                        </div>
                        <div class="payment-method__method-popup-method-checkbox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <polygon fill="currentColor" points="9 14.07 4.93 10 6.34 8.59 9 11.24 13.66 6.59 15.07 8 9 14.07"/>
                            </svg>
                        </div>
                    </div>
                    <!-- END: Apple Pay -->

                    <!-- END: wallets -->
                    <!-- BEGIN: cards -->

                    <div class="payment-method__method-popup-method" *ngIf="isAnyCardEnabled && !hasCards" (click)="onNewCardClick()">
                        <div class="payment-method__method-popup-method-text">{{ cardOptionLabelKey | translate }}</div>
                        <div class="payment-method__method-popup-method-logos">
                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 50 24" class="payment-method__method-popup-method-logo payment-method__method-popup-method-logo_cards">
                                <rect x="26" width="24" height="16" rx="2" fill="#016fd0"/>
                                <polygon points="49.91 2.63 49.91 9.28 48.74 10.53 49.91 11.79 49.91 13.4 48.04 13.4 47.04 12.3 46.06 13.41 39.76 13.39 39.76 8.33 37.73 8.33 40.24 2.63 42.68 2.63 43.54 4.58 43.54 2.63 46.56 2.63 47.08 4.09 47.61 2.63 49.91 2.63" fill="#fff" fill-rule="evenodd"/>
                                <path d="M40.44,12.77V8.32h3.77v1H41.66V10h2.49v1H41.66v.68h2.55v1Zm3.76,0,2.08-2.23L44.2,8.32h1.61l1.28,1.41,1.28-1.41h1.54l-2,2.18,2,2.17v.06H48.35l-1.3-1.43-1.28,1.43ZM40.7,3.25l-2,4.45h1.35l.37-.89h2l.37.89h1.39l-2-4.45Zm.17,2.56.59-1.42.59,1.42ZM44.21,7.7V3.25h1.91l1,2.73,1-2.74H50V7.7H48.73v-3L47.62,7.7H46.54L45.41,4.64V7.7Z" fill="#016fd0" fill-rule="evenodd"/>
                                <rect x="14" y="4" width="24" height="16" rx="2" fill="#252525"/>
                                <circle cx="23" cy="12" r="5" fill="#eb001b"/>
                                <circle cx="29" cy="12" r="5" fill="#f79e1b"/>
                                <path d="M26,8a5,5,0,0,1,1,7,4.74,4.74,0,0,1-1,1,5,5,0,0,1-1-7A4.74,4.74,0,0,1,26,8Z" fill="#ff5f00" fill-rule="evenodd"/>
                                <rect x="0.5" y="8.5" width="23" height="15" rx="1.75" fill="#fff"/>
                                <path d="M2.79,13.91A7.74,7.74,0,0,0,1,13.24v-.13H3.76a.68.68,0,0,1,.77.52l.6,2.84.18.85L7,13.11H8.8L6.1,19.28H4.3Zm7.31,5.37H8.4l1.06-6.17h1.7Zm6.17-6L16,14.6l-.16-.07a3.06,3.06,0,0,0-1.27-.24c-.67,0-1,.27-1,.54s.37.47,1,.76c1,.44,1.44,1,1.43,1.68,0,1.28-1.17,2.11-3,2.11a5.59,5.59,0,0,1-1.9-.33l.24-1.39.23.1a3.63,3.63,0,0,0,1.59.33c.49,0,1-.19,1-.61,0-.26-.22-.46-.88-.76A2,2,0,0,1,11.87,15c0-1.2,1.19-2,2.87-2a4.68,4.68,0,0,1,1.53.26Zm2.26,3.82h1.41c-.07-.31-.39-1.79-.39-1.79l-.12-.53c-.08.23-.23.6-.22.59Zm2.1-4L22,19.28H20.42s-.15-.71-.2-.92H18c-.07.16-.36.92-.36.92H15.9l2.52-5.66a.85.85,0,0,1,.89-.51Z" fill="#171e6c"/>
                                <path d="M21.75,24H2.25A2.25,2.25,0,0,1,0,21.75V10.25A2.25,2.25,0,0,1,2.25,8h19.5A2.25,2.25,0,0,1,24,10.25v11.5A2.25,2.25,0,0,1,21.75,24ZM2.25,8.5A1.77,1.77,0,0,0,.5,10.25v11.5A1.77,1.77,0,0,0,2.25,23.5h19.5a1.77,1.77,0,0,0,1.75-1.75V10.25A1.77,1.77,0,0,0,21.75,8.5Z" fill="#ccc"/>
                            </svg>
                        </div>
                        <div class="payment-method__method-popup-method-checkbox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <polygon fill="currentColor" points="9 14.07 4.93 10 6.34 8.59 9 11.24 13.66 6.59 15.07 8 9 14.07"/>
                            </svg>
                        </div>
                    </div>

                    <div class="payment-method__method-popup-method payment-method__method-popup-method_card"
                        *ngFor="let cardItem of cards"
                        [ngClass]="{
                            'payment-method__method-popup-method_active': selectedPaymentMethod && selectedPaymentMethod.type === cardItem.type && selectedPaymentMethod.id === cardItem.card.id,
                            'payment-method__method-popup-method_unavailable': !cardItem.isAvailable
                        }"
                        (click)="onMethodClick(cardItem.type, cardItem.card.id, $event)"
                    >
                        <div class="payment-method__method-popup-method-text">
                            {{ cardTypes[cardItem.card.funding] | translate }} {{ cardBrands[cardItem.card.brand] }} *{{ cardItem.card.last4 }}
                            <div class="payment-method__method-popup-method-subtext" *ngIf="!cardItem.isAvailable">
                                {{ 'payment_method.method_unacceptable' | translate }}
                            </div>
                        </div>
                        <button class="payment-method__method-popup-method-delete"
                            *ngIf="selectedPaymentMethod.id !== cardItem.card.id && paymentConfig.payment_method_id !== cardItem.card.id"
                            (click)="onDeleteCardClick(cardItem.card, $event)"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <polygon fill="currentColor" points="15.95 14.54 14.54 15.95 10 11.41 5.46 15.95 4.05 14.54 8.59 10 4.05 5.46 5.46 4.05 10 8.59 14.54 4.05 15.95 5.46 11.41 10 15.95 14.54"/>
                            </svg>
                        </button>
                        <div class="payment-method__method-popup-method-checkbox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <polygon fill="currentColor" points="9 14.07 4.93 10 6.34 8.59 9 11.24 13.66 6.59 15.07 8 9 14.07"/>
                            </svg>
                        </div>
                    </div>

                    <button class="button button_regular button_light-blue payment-method__method-popup-add-card"
                        *ngIf="isAnyCardEnabled && hasCards"
                        (click)="onNewCardClick()"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="button_icon">
                            <path d="M12,6v6h0V6m1-1H11v6H5v2h6v6h2V13h6V11H13V5Z" fill="currentColor"/>
                        </svg>
                        <span class="button__caption">{{ addCardButtonCaptionKey | translate }}</span>
                    </button>

                    <!-- END: cards -->
                </div>
            </ng-container>
        </popup-content>
        <ng-container [ngSwitch]="methodPopupState">
            <popup-controls *ngSwitchCase="'error'" [isStretched]="true">
                <button type="button" class="button button_regular button_light-blue" (click)="onCancelMethod()">
                    <span class="button__caption">{{ 'payment_method.error_cancel' | translate }}</span>
                </button>
            </popup-controls>
            <popup-controls *ngSwitchCase="'ready'" [isStretched]="true">
                <button type="button" class="button button_regular button_light-blue"
                    *ngIf="!hideCancelButton && mode !== 'setup'"
                    (click)="onCancelMethod()"
                    [ngSwitch]="mode"
                >
                    <span class="button__caption" *ngSwitchCase="'edit'">{{ 'payment_method.setup_close' | translate }}</span>
                    <span class="button__caption" *ngSwitchDefault>{{ 'payment_method.setup_cancel' | translate }}</span>
                </button>
                <button type="button" class="button button_regular button_blue"
                    [isDisabled]="!canSubmitMethod()"
                    [isProgress]="isMethodPopupSubmitting"
                    (click)="onSubmitMethod()"
                    [ngSwitch]="mode"
                >
                    <span class="button__caption" *ngSwitchCase="'setup'">{{ 'payment_method.setup_use_as_method' | translate }}</span>
                    <span class="button__caption" *ngSwitchDefault>{{ 'payment_method.setup_ok' | translate }}</span>
                </button>
            </popup-controls>
        </ng-container>
    </popup-custom>

    <!-------------------------------------------------------->

    <popup-custom class="payment-method__card-popup"
        [isDisabled]="isCardPopupSubmitting"
        *ngSwitchCase="'card'"
    >
        <popup-content>
            <div class="payment-method__card-popup-fatal" *ngIf="cardPopupState === 'error'">
                {{ 'stripe.error.common.generic' | translate }}
            </div>
            <ng-container *ngIf="cardPopupState !== 'error'">
                <div class="payment-method__card-popup-header">{{ cardPopupHeaderKey | translate }}</div>
                <div class="payment-method__card-popup-error" *ngIf="cardPopupError">
                    {{ cardPopupError.key | translate : cardPopupError.data }}
                </div>
                <div #cardNumberNest class="payment-method__card-popup-input payment-method__card-popup-input_card"></div>
                <div class="payment-method__card-popup-secondary-inputs">
                    <div #cardExpireNest class="payment-method__card-popup-input payment-method__card-popup-input_expire"></div>
                    <div #cardCVCNest class="payment-method__card-popup-input payment-method__card-popup-input_cvc"></div>
                </div>
                <div class="payment-method__card-popup-secured">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="payment-method__card-popup-secured-lock">
                        <path fill="currentColor" d="M12.24,6.52V5.24a4.24,4.24,0,0,0-8.48,0V6.52a1.7,1.7,0,0,0-1.7,1.69V13.3A1.7,1.7,0,0,0,3.76,15h8.48a1.7,1.7,0,0,0,1.7-1.7V8.21A1.7,1.7,0,0,0,12.24,6.52ZM8.85,11.38v1.26a.66.66,0,0,1-.66.66H7.81a.66.66,0,0,1-.66-.66V11.38a1.7,1.7,0,1,1,1.7,0Zm1.7-4.86H5.45V5.24a2.55,2.55,0,0,1,5.1,0Z"/>
                    </svg>
                    <span>{{ 'payment_method.card.secured' | translate }}</span>
                    <a target="_blank" rel="nofollow noopener" href="https://stripe.com/" class="payment-method__card-popup-secured-link">
                        <svg xmlns="http://www.w3.org/2000/svg" width="378" height="165.5" viewBox="0 0 378 165.5" class="payment-method__card-popup-secured-stripe">
                            <path fill="currentColor" d="M369,84.9c0-25.6-12.4-45.8-36.1-45.8s-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3,11.9,0,20.9-2.7,27.7-6.5v-20a53.1,53.1,0,0,1-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C368.8,92.5,369,87.3,369,84.9Zm-49.4-9.5c0-11.3,6.9-16,13.2-16,6.1,0,12.6,4.7,12.6,16ZM256.1,39.1a28.25,28.25,0,0,0-19.6,7.8l-1.3-6.2h-22V157.3l25-5.3.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3,17.9,0,34.2-14.4,34.2-46.1C290.1,54.9,273.6,39.1,256.1,39.1Zm-6,68.9c-5.9,0-9.4-2.1-11.8-4.7l-.1-37.1c2.6-2.9,6.2-4.9,11.9-4.9,9.1,0,15.4,10.2,15.4,23.3C265.5,98,259.3,108,250.1,108ZM178.8,33.2l25.1-5.4V7.5l-25.1,5.3Zm0,7.6h25.1v87.5H178.8Zm-26.9,7.4-1.6-7.4H128.7v87.5h25V69c5.9-7.7,15.9-6.3,19-5.2v-23C169.5,39.6,157.8,37.4,151.9,48.2Zm-50-29.1L77.5,24.3l-.1,80.1c0,14.8,11.1,25.7,25.9,25.7,8.2,0,14.2-1.5,17.5-3.3V106.5c-3.2,1.3-19,5.9-19-8.9V62.1h19V40.8h-19ZM34.3,66.2c0-3.9,3.2-5.4,8.5-5.4a55.78,55.78,0,0,1,24.8,6.4V43.7a66,66,0,0,0-24.8-4.6C22.5,39.1,9,49.7,9,67.4,9,95,47,90.6,47,102.5c0,4.6-4,6.1-9.6,6.1-8.3,0-18.9-3.4-27.3-8v23.8a69.32,69.32,0,0,0,27.3,5.7c20.8,0,35.1-10.3,35.1-28.2C72.4,72.1,34.3,77.4,34.3,66.2Z"/>
                        </svg>
                    </a>
                </div>
                <checkbox [(ngModel)]="cardForm.isAccepted" class="payment-method__card-popup-agreement">
                    <span class="payment-method__card-popup-agreement-caption">{{ 'payment_method.card.agreement_checkbox' | translate }}</span>
                </checkbox>
            </ng-container>
        </popup-content>
        <ng-container [ngSwitch]="cardPopupState">
            <popup-controls *ngSwitchCase="'error'" [isStretched]="true">
                <button type="button" class="button button_regular button_light-blue" (click)="onCancelCard()">
                    <span class="button__caption">{{ 'payment_method.card.error_cancel' | translate }}</span>
                </button>
            </popup-controls>
            <popup-controls *ngSwitchDefault [isStretched]="true">
                <button type="button" class="button button_regular button_light-blue" (click)="onCancelCard()">
                    <span class="button__caption">{{ 'payment_method.card.submit_cancel' | translate }}</span>
                </button>
                <button type="button" class="button button_regular button_blue"
                    [isDisabled]="isCardPopupSubmitting || !isCardValid()"
                    [isProgress]="isCardPopupSubmitting"
                    (click)="onSubmitCard()"
                >
                    <span class="button__caption">{{ 'payment_method.card.submit_ok' | translate }}</span>
                </button>
            </popup-controls>
        </ng-container>
        <div class="payment-method__card-popup-loading" *ngIf="cardPopupState === 'init'">
            <loader></loader>
        </div>
    </popup-custom>

    <!-------------------------------------------------------->

    <popup-custom class="payment-method__card-delete-popup"
        [isDisabled]="isCardDeletePopupSubmitting"
        *ngSwitchCase="'card-delete'"
    >
        <popup-content>
            <span [innerHTML]="'payment_method.card_delete.message' | translate : { card: (cardBrands[cardToDelete.brand] + ' *' + cardToDelete.last4) }"></span>
        </popup-content>
        <popup-controls [isStretched]="true">
            <button type="button" class="button button_regular button_light-blue" (click)="onCancelDeleteCard()">
                <span class="button__caption">{{ 'payment_method.card_delete.cancel' | translate }}</span>
            </button>
            <button type="button" class="button button_regular button_blue"
                [isDisabled]="isCardDeletePopupSubmitting"
                [isProgress]="isCardDeletePopupSubmitting"
                (click)="onSubmitDeleteCard()"
            >
                <span class="button__caption">{{ 'payment_method.card_delete.ok' | translate }}</span>
            </button>
        </popup-controls>
    </popup-custom>

    <!-------------------------------------------------------->

    <popup-custom class="payment-method__venmo-delete-popup"
        [isDisabled]="isVenmoDeletePopupSubmitting"
        *ngSwitchCase="'venmo-delete'"
    >
        <popup-content>
            <span [innerHTML]="'payment_method.venmo_delete.message' | translate : { venmoAccount: venmoAccountToDetach }"></span>
        </popup-content>
        <popup-controls [isStretched]="true">
            <button type="button" class="button button_regular button_light-blue" (click)="onCancelDeleteVenmo()">
                <span class="button__caption">{{ 'payment_method.venmo_delete.cancel' | translate }}</span>
            </button>
            <button type="button" class="button button_regular button_blue"
                [isProgress]="isVenmoDeletePopupSubmitting"
                (click)="onSubmitDeleteVenmo()"
            >
                <span class="button__caption">{{ 'payment_method.venmo_delete.ok' | translate }}</span>
            </button>
        </popup-controls>
    </popup-custom>
</ng-container>
