import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {TooltipModule} from 'ng2-tooltip-directive';
import {CheckboxComponent} from '../_widgets/checkbox/checkbox.component';
import {ButtonComponent} from '../_widgets/button/button.component';
import {ToastComponent} from '../_widgets/toast/toast.component';
import {ToastManagerComponent} from '../_widgets/toast/toast-manager.component';
import {LoaderComponent} from '../_widgets/loader/loader.component';
import {CurrencyPipe} from '../pipes/currency.pipe';
import {DatetimePipe} from '../pipes/datetime.pipe';
import {CheckboxStaticComponent} from '../_widgets/checkbox-static/checkbox-static.component';
import {ContextPanelComponent} from '../_widgets/context-panel/context-panel.component';
import {PopupComponent} from '../_widgets/popup/popup.component';
import {PopupHeaderComponent} from '../_widgets/popup/popup-header.component';
import {PopupContentComponent} from '../_widgets/popup/popup-content.component';
import {PopupControlsComponent} from '../_widgets/popup/popup-controls.component';
import {ConfirmBoxComponent} from '../_widgets/popup/confirm-box/confirm-box.component';
import {ValidationErrorsComponent} from "./validation-errors/validation-errors.component";



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        TranslateModule,
        TooltipModule,
    ],
    declarations: [
        CheckboxComponent,
        CheckboxStaticComponent,
        ButtonComponent,
        ToastComponent,
        ToastManagerComponent,
        LoaderComponent,
        ContextPanelComponent,
        PopupComponent,
        PopupHeaderComponent,
        PopupContentComponent,
        PopupControlsComponent,
        ConfirmBoxComponent,
        ValidationErrorsComponent,

        // Directives

        // Pipes
        CurrencyPipe,
        DatetimePipe,
    ],
    exports: [
        // Modules
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        TranslateModule,
        TooltipModule,

        // Components
        CheckboxComponent,
        CheckboxStaticComponent,
        ButtonComponent,
        ToastComponent,
        ToastManagerComponent,
        LoaderComponent,
        ContextPanelComponent,
        PopupComponent,
        PopupHeaderComponent,
        PopupContentComponent,
        PopupControlsComponent,
        ConfirmBoxComponent,
        ValidationErrorsComponent,

        // Directives

        // Pipes
        CurrencyPipe,
        DatetimePipe,
    ],
    entryComponents: [

    ],
    providers: []
})
export class SharedModule {}
