import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    Renderer2, ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {Router} from '@angular/router';
import {animateChild, query, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {TitleService} from '../../../services/title.service';
import {DeviceService, ViewportBreakpoint} from '../../../services/device.service';
import {
    AllLicensePlatesHttpResponse,
    LicensePlateItem,
    LicensePlatesService, PendingLPN,
    PendingLPNResponse, PendingLPNsInvoiceResponse
} from '../../../services/license-plates.service';
import {ToastService} from '../../../services/toast.service';
import {Base64Service} from '../../../services/base64.service';
import {Location} from '@angular/common';
import {
    MakePaymentByEmailResponse,
    PayByMailData,
    PaymentConfig, PaymentMethodType,
    PaymentService
} from '../../../services/payment.service';
import {PaymentMethodWallet, StripeService} from '../../../services/stripe.service';
import {CurrencyService} from '../../../services/currency.service';
import {InvoicePaymentComponent} from '../../invoices/invoice-payment/invoice-payment.component';
import {PaymentMethodDoneEvent} from '../../payment-method/payment-method.component';
import {
    InvoicePaymentInvoice,
    InvoicePaymentResponseWithError,
    InvoicesService
} from '../../../services/invoices.service';
import {AccountPaymentModel, UserService} from '../../../services/user.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormValidationService} from "../../../_shared/validation-errors/form-validation.service";
import {
    minDateTodayValidator, minDateValidator,
    minOneHourDurationValidator, minOneHourExtendDurationValidator
} from "../../../_shared/validators/date-validator";
import {paymentActions} from "../../dashboard.component";

type ListState = 'loading' | 'list' | 'empty' | 'error';
type ActivePopup =
    'add'
    | 'unregister-confirm'
    | 'payment-result'
    | 'payment'
    | 'payment-method'
    | 'error'
    | 'fleet-lpn'
    | 'fleet-lpn-zip'
    | 'fleet-wallet-payment-confirm'
    | 'extend-rental-lpn-period';

type PaymentSubmittingBy = null | 'yes' | 'no';

interface ErrorMessage {
    key: string;
    data?: any
}

enum API_RESPONSE_CODES {
    'GENERIC_ERROR' = 1,
    'AUTHENTICATION_ERROR' = 100,
    'AUTHENTICATION_REQUIRED' = 101,
    'PIN_DEACTIVATED' = 102,
    'MISSING_ROLE' = 103,
    'REQUEST_THROTTLED' = 104,
    'ACCOUNT_LOCKED' = 105,
    'STRIPE_WEBHOOK_SIGNATURE_ERROR' = 106,
    'ACCOUNT_EXISTS' = 200,
    'INVALID_DATA' = 201,
    'INVALID_PAYMENT_DATA' = 202,
    'LICENSE_PLATE_REGISTRATION_ERROR' = 203,
    'OUTSTANDING_INVOICE_EXISTS' = 204,
    'LICENSE_PLATE_REGISTERED_WITHOUT_PAYMENT' = 205,
    'STRIPE_WEBHOOK_DATA_ERROR' = 206,
    'INVALID_REQUEST' = 207,
    'INVALID_LICENSE_PLATE_FORMAT' = 208,
    'LICENSE_PLATE_REGISTRATION_WITH_ACCOUNT_LOCK' = 209,
    'LICENSE_PLATE_REGISTRATION_WITH_PAYMENT_LOCK' = 210,
    'LICENSE_PLATE_ASSIGNED_TO_ACCOUNT_ERROR' = 211,
    'LICENSE_PLATE_BELONGS_TO_ANOTHER_FLEET' = 212,
    'LICENSE_PLATE_ALREADY_REGISTERED' = 213,
    'PAYPAL_WEBHOOK_DATA_ERROR' = 214,
    'LICENSE_PLATE_WITH_OUTSTANDING_PBM_INVOICE' = 215,
    'LICENSE_PLATE_REGISTRATION_FEE_REQUIRED' = 216,
    'PENDING_DISPUTE_EXISTS' = 217,
    'INVALID_RENTAL_END_DATE' = 218,
    'DCB_INSUFFICIENT_CREDIT' = 300,
    'PAYMENT_GATEWAY_ERROR' = 301,
    'PAYMENT_LOCK' = 302,
    'ZIP_CODE_LIMIT_REACHED' = 303,
    'ZIP_CODE_MISMATCH' = 304,
    'PAYMENT_CHANNEL_NOT_DEFINED' = 305,
    'THIRD_PARTY_PAYMENT_SETUP_ERROR' = 306,
    'REFUND_REQUIRED_ERROR' = 307,
    'PAYMENT_CARD_DECLINED' = 308,
    'CARD_INSUFFICIENT_CREDIT' = 309,
    'CARD_NOT_SUPPORTED' = 310,
    'PAYMENT_POTENTIAL_FRAUD' = 311,
    'PAYMENT_VELOCITY_ERROR' = 312,

}

interface WelcomeMessageData {
    licensePlates: string;
    hoursToPay: number;
    invoice: string;
    amount: string;
}

@Component({
    selector: 'vehicles',
    exportAs: 'vehicles',
    templateUrl: './vehicles.component.html',
    styleUrls: ['./vehicles.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        'class': 'vehicles',
        '[@vehiclesHost]': 'true'
    },
    animations: [
        trigger('vehiclesHost', [
            transition(':enter', [
                query('@*', animateChild(), {optional: true}),
            ]),
        ]),
    ]
})
export class VehiclesComponent implements OnInit, OnDestroy {
    viewportBreakpoint: ViewportBreakpoint;
    private readonly _notification_message_timeout_MS = 9000;
    subs: Subscription[] = [];

    listState: ListState = 'loading';

    listOfLicensePlates: LicensePlateItem[];
    listOfRentalLicensePlates: LicensePlateItem[];

    isLoading: boolean = false;

    lpToUnregister: LicensePlateItem;

    activePopup: null | ActivePopup = null;

    isLpnValid: boolean = false;

    newLpnName: string = '';
    newLpnIsRentalCheckboxModel: boolean = false;

    addPopupError: ErrorMessage = null;

    errorPopupMessage: ErrorMessage = null;

    paymentConfig: PaymentConfig = null;

    stripe: any;

    walletPaymentAttrs: {
        paymentRequest: any;
    } = null;

    paymentSubmittingBy: PaymentSubmittingBy = null;

    isPaymentZipRequired: boolean = false;

    zipCode: string = '';

    payByMailConfirmMessageKey: string;

    paymentResultMessageKey: string;

    paymentResultMessageData: WelcomeMessageData = {
        licensePlates: '',
        hoursToPay: 47,
        invoice: '',
        amount: '',
    };

    @ViewChild('paymentComponent', {read: InvoicePaymentComponent})
    paymentComponent: InvoicePaymentComponent;

    pbmData: PayByMailData;

    isPaymentPopupValid: boolean = false;

    lastLPN: string;

    // --------------------------------------------------------

    accountPaymentModel: AccountPaymentModel = null;

    listOfPendingLPNs: PendingLPNResponse = null;

    isSelectedForPaymentPendingLPNByIdMap: { [id: string]: boolean } = null;
    mapOfDatesForRentalPendingLPNs: {} = null;

    selectedForPaymentPendingLPNsCount: number = 0;

    activePendingLPNsTotal: number = 0;

    pendingLPNsInvoice: PendingLPNsInvoiceResponse = null;

    isConfirmingFleet: boolean = false;

    isFleetZipSubmitting: boolean = false;

    isFleetZipValid: boolean = false;

    isFleetWalletSubmitting: boolean = false;

    walletFleetPaymentAttrs: {
        amountFormatted: string;
        wallet: string;
        paymentRequest: any;
    } = null;

    attemptsToSelectPaymentMethodForFleet: number = 0;

    updateTimeout: any = null;

    extendLPNPeriodForm: FormGroup = new FormGroup({
        end_date: new FormControl(null, Validators.required),
        end_time: new FormControl(null, Validators.required),

    });
    public selectedLPNForExtend: LicensePlateItem | null = null;

    pendingLPNsForm: FormGroup;


    constructor(
        private renderer: Renderer2,
        private router: Router,
        private location: Location,
        private titleService: TitleService,
        private deviceService: DeviceService,
        private licensePlatesService: LicensePlatesService,
        private toastService: ToastService,
        private paymentService: PaymentService,
        private stripeService: StripeService,
        private currencyService: CurrencyService,
        private invoicesService: InvoicesService,
        private base64Service: Base64Service,
        private userService: UserService,
        public formValidationService: FormValidationService,
        public fb: FormBuilder,
        public formValidatorService: FormValidationService,
    ) {
        window.scroll(0, 0);

        this.accountPaymentModel = this.userService.getUserData().account.paymentModel;

        this.viewportBreakpoint = this.deviceService.viewportBreakpoint;
        this.subs.push(this.deviceService.onResize.subscribe((message) => {
            if (message.breakpointChange) {
                this.viewportBreakpoint = message.breakpointChange.current;
            }
        }));

        this.listState = 'loading';
    }

    async ngOnInit() {
        this.titleService.setTitle('vehicles.page_title');
        await this.init();
        this.pendingLPNsForm = this.fb.group({});
    }

    async init() {
        await this.makePaymentActions();
        this.getAllLicensePlates();
    }

    private async makePaymentActions() {
        const currentUrl = new URL(window.location.href);
        const paymentAction: paymentActions = currentUrl.searchParams.get('action') as paymentActions;

        if (paymentAction) {
            switch (paymentAction) {
                case 'veh_pbm_paypal_payment_complete':
                case 'veh_pbm_paypal_payment_cancel': {
                    this.location.replaceState('/dashboard/profile/vehicles');

                    const result = this.decodeUrlToJson(currentUrl.searchParams.get('veh_pbm_paypal_payment_result'));

                    if (result) {
                        this.pbmData = result.pbmData;
                        this.paymentResultMessageData = result.messageData;

                        if (paymentAction === 'veh_pbm_paypal_payment_cancel') {
                            await this.declinePBM(this.pbmData, 'paypal payment canceled or failed');
                        }

                        this.showSuccessPaymentMessage(result.messageKey);

                        const transactionId = currentUrl.searchParams.get('transaction_id');

                        if (transactionId && paymentAction === 'veh_pbm_paypal_payment_complete') {
                            await this.paymentService.completePaymentIntent(transactionId).toPromise().catch(() => false);
                        }
                    }

                    break;
                }
                case 'veh_fleet_lpn_ppp_complete':
                case 'veh_fleet_lpn_ppp_cancel': {
                    this.location.replaceState('/dashboard/profile/vehicles');

                    const result = this.decodeUrlToJson(currentUrl.searchParams.get('veh_fleet_lpn_ppp_result'));

                    if (result) {
                        const transactionId = currentUrl.searchParams.get('transaction_id');

                        if (transactionId && paymentAction === 'veh_fleet_lpn_ppp_complete') {
                            await this.paymentService.completePaymentIntent(transactionId).toPromise().catch(() => false);
                        }

                        this.paymentResultMessageData = result.messageData;
                        this.showFleetResultMessage(result.messageKey);
                    }

                    this.setIntervalUpdate();

                    break;
                }
            }
        }
    }

    public addControlToPendingLPNsForm(controlName: string) {
        this.pendingLPNsForm.addControl(controlName, this.fb.group({
            endDate: ['', Validators.compose([Validators.required, minDateTodayValidator()])],
            endTime: ['', Validators.compose([Validators.required, minOneHourDurationValidator()])],
        }));
    }

    encodeJsonToUrl(data: any): string {
        return encodeURIComponent(this.base64Service.encode(JSON.stringify(data)));
    }

    decodeUrlToJson(data: any): any {
        try {
            return JSON.parse(this.base64Service.decode(decodeURIComponent(data)));
        } catch (e) {
            return null;
        }
    }

    async executePBM(isPaymentMethodChanged: boolean) {
        const response: [any, PayByMailData, PaymentConfig, number] = await Promise.all([
            this.stripeService.getStripeInstance(),
            this.paymentService.fetchPayByMail().toPromise(),
            this.paymentService.fetchPaymentConfig().toPromise(),
            this.paymentService.fetchHoursToPay().toPromise(),
        ]).catch(() => null);

        if (!response) {
            this.showSuccessPaymentMessage('profile.pbm.common_error');
            console.warn('Something wrong with PBM init');
            return;
        }

        const [stripe, pbmData, paymentConfig, hoursToPay] = response;

        if (!pbmData || pbmData.status !== 'OK' || !pbmData.pbm_id) {
            this.showSuccessPaymentMessage('profile.pbm.common_error');
            console.warn('Something wrong with PBM init');
            return;
        }

        this.stripe = stripe;
        this.paymentResultMessageData.hoursToPay = hoursToPay;
        this.paymentResultMessageData.licensePlates = `<strong>${this.lastLPN}</strong>`;

        const isPaymentMethodOk = await this.paymentService.checkCurrentPaymentMethod(paymentConfig, null, {
            amount: pbmData.amount,
            currency: pbmData.currency
        }).catch(() => false);

        if (!isPaymentMethodOk) {
            if (isPaymentMethodChanged) {
                await this.declinePBM(pbmData, 'something wrong with payment method');
                this.showSuccessPaymentMessage('profile.pbm.common_error');
            } else {
                this.showPaymentMethodPopup();
            }
            return;
        }

        const isAmountLessThanMin = pbmData.amount < paymentConfig.min_payment_amount;
        const isAmountGreaterThanMax = pbmData.amount > paymentConfig.max_payment_amount;

        if (isAmountLessThanMin || isAmountGreaterThanMax) {
            await this.declinePBM(pbmData, 'limits');
            this.showSuccessPaymentMessage('profile.pbm.common_error');
            return;
        }

        this.paymentConfig = paymentConfig;
        this.pbmData = pbmData;
        this.isPaymentZipRequired = paymentConfig.payment_verification_required;

        this.payByMailConfirmMessageKey = (
            this.paymentConfig.payment_method_type === 'DCB' && this.isPaymentZipRequired ?
                'profile.pbm.confirm_with_zip' :
                'profile.pbm.confirm_without_zip'
        );

        this.paymentResultMessageData.invoice = this.pbmData.name;
        this.paymentResultMessageData.amount = this.currencyService.format(this.pbmData.amount, this.pbmData.currency);

        if (paymentConfig.payment_method_type === 'GOOGLEPAY' || paymentConfig.payment_method_type === 'APPLEPAY') {
            await this.prepareWalletPayment();
        } else {
            this.showPaymentPopup();
        }
    }

    async declinePBM(payByMailData: PayByMailData, reason: string = null): Promise<void> {
        const response = await this.sendPaymentRequest({
            pbmId: payByMailData.pbm_id,
            makePayment: false,
        });

        if (response.status === 'OK') {
            console.warn(`PBM rejected (reason: ${reason})`);
        } else {
            console.warn(`PBM must be rejected (reason: ${reason}) but an error occurred`)
        }
    }

    async sendPaymentRequest(options: {
        pbmId: string,
        makePayment: boolean,
        verificationCode?: string,
        returnUrl?: string,
        cancelUrl?: string,
    }): Promise<MakePaymentByEmailResponse> {
        return this.paymentService.makePaymentByEmail(options.pbmId, {
            make_payment: options.makePayment,
            verification_code: options.verificationCode || null,
            payment_method_type: this.paymentConfig && this.paymentConfig.payment_method_type || null,
            payment_method_id: this.paymentConfig && this.paymentConfig.payment_method_id || null,
            return_url: options.returnUrl || null,
            cancel_url: options.cancelUrl || null,
        })
            .toPromise()
            .then(response => {
                console.warn(response);
                if (response.status !== 'OK' && !response.errorCode) {
                    response.errorCode = API_RESPONSE_CODES.LICENSE_PLATE_REGISTERED_WITHOUT_PAYMENT;
                }

                return response;
            })
            .catch((errorCode: number) => {
                console.warn(errorCode);
                return {
                    status: 'ERROR',
                    errorCode
                };
            });
    }

    showSuccessPaymentMessage(paymentResultMessageKey: string) {
        this.paymentResultMessageKey = paymentResultMessageKey;
        this.activePopup = 'payment-result';
    }

    hidePaymentResultMessage() {
        this.activePopup = null;
    }

    showPaymentError(_: number) {
        /*switch (errorCode) {
            case 203:
            case API_RESPONSE_CODES.LICENSE_PLATE_REGISTERED_WITHOUT_PAYMENT:
            case 307:
                this.showSuccessPaymentMessage(`dashboard.welcome.message_${ errorCode }`);
                return;
        }*/

        this.showSuccessPaymentMessage('profile.pbm.common_error');
    }

    async payByPayPal() {
        const successResultData = this.encodeJsonToUrl({
            messageKey: 'profile.pbm.message_approved',
            messageData: this.paymentResultMessageData,
            pbmData: this.pbmData
        });

        const cancelResultData = this.encodeJsonToUrl({
            messageKey: 'profile.pbm.common_error',
            messageData: this.paymentResultMessageData,
            pbmData: this.pbmData
        });

        const response = await this.sendPaymentRequest({
            pbmId: this.pbmData.pbm_id,
            makePayment: true,
            returnUrl: `/dashboard/profile/vehicles?action=veh_pbm_paypal_payment_complete&veh_pbm_paypal_payment_result=${successResultData}`,
            cancelUrl: `/dashboard/profile/vehicles?action=veh_pbm_paypal_payment_cancel&veh_pbm_paypal_payment_result=${cancelResultData}`,
        });

        const approveUrl = response?.payment_intent?.approve_payment_url;

        if (!response || response.status !== 'OK' || !approveUrl) {
            await this.declinePBM(this.pbmData, 'fetch paypal order error');
            this.showPaymentError(response && response.errorCode || API_RESPONSE_CODES.LICENSE_PLATE_REGISTERED_WITHOUT_PAYMENT);
        } else {
            window.location.assign(approveUrl);
        }
    }

    async payByCarrier() {
        const zipCode: string = this.isPaymentZipRequired ? (this.zipCode || '').trim() : null;
        const intentResponse = await this.sendPaymentRequest({
            pbmId: this.pbmData.pbm_id,
            makePayment: true,
            verificationCode: zipCode,
        });

        this.getAllLicensePlates();

        console.warn(intentResponse);

        if (intentResponse.status === 'OK') {
            this.showSuccessPaymentMessage('profile.pbm.message_approved');
        } else {
            this.showPaymentError(intentResponse && intentResponse.errorCode || API_RESPONSE_CODES.LICENSE_PLATE_REGISTERED_WITHOUT_PAYMENT);
        }
    }

    async payByCard() {
        debugger;
        const intentResponse = await this.sendPaymentRequest({
            pbmId: this.pbmData.pbm_id,
            makePayment: true,
        });

        if (!intentResponse || intentResponse.status !== 'OK') {
            await this.declinePBM(this.pbmData, 'fetch payment intent error');
            this.showPaymentError(intentResponse && intentResponse.errorCode || API_RESPONSE_CODES.LICENSE_PLATE_REGISTERED_WITHOUT_PAYMENT);
            return;
        }

        // Payment done w/o 3D secure
        if (intentResponse.payment_complete) {
            this.getAllLicensePlates();
            this.showSuccessPaymentMessage('profile.pbm.message_approved');
            return;
        }

        const paymentIntent = intentResponse.payment_intent;

        // There is payment intent with status !== 'succeeded'
        // 3D secure required
        const response = await this.stripe.confirmCardPayment(paymentIntent.client_secret).catch(() => null);

        if (!response || response.error) {
            console.warn(response);
            await this.declinePBM(this.pbmData, '3D Secure failed');
            this.showSuccessPaymentMessage('profile.pbm.common_error');
            return;
        }

        const isPaymentIntentCompleted = await this.paymentService.completePaymentIntent(intentResponse.transaction_id).toPromise().catch(() => false);

        if (isPaymentIntentCompleted) {
            this.getAllLicensePlates();
            this.showSuccessPaymentMessage('profile.pbm.message_approved');
            return;
        }

        // Unexpected state or 3D secure fail
        this.showSuccessPaymentMessage('profile.pbm.common_error');
    }

    async prepareWalletPayment() {
        const {paymentRequest} = await this.stripeService.getWalletPaymentRequest({
            amount: this.pbmData.amount,
            currency: this.pbmData.currency,
            label: 'Total',
        });

        paymentRequest.on('paymentmethod', async (e) => {
            // [POST /pay-by-mail/<pbm_id>]
            const intentResponse = await this.sendPaymentRequest({
                pbmId: this.pbmData.pbm_id,
                makePayment: true,
            });

            if (!intentResponse || intentResponse.status !== 'OK' || intentResponse.errorCode || !intentResponse.payment_intent) {
                e.complete('fail');
                await this.declinePBM(this.pbmData, 'fetch payment intent error');
                this.showPaymentError(intentResponse && intentResponse.errorCode || API_RESPONSE_CODES.LICENSE_PLATE_REGISTERED_WITHOUT_PAYMENT);
                return;
            }

            const paymentIntent = intentResponse.payment_intent;
            const confirmResponse = await this.stripe.confirmCardPayment(paymentIntent.client_secret, {
                payment_method: e.paymentMethod.id
            }, {
                handleActions: false
            });

            if (confirmResponse.error) {
                e.complete('fail');
                await this.declinePBM(this.pbmData, 'fail to pay with wallet');
                this.showSuccessPaymentMessage('profile.pbm.common_error');
                return;
            }

            e.complete('success');
            // ------------------------
            // WALLET UI IS HIDDEN HERE
            // ------------------------

            let isOk = true;

            if (confirmResponse.paymentIntent.status === 'requires_action') {
                const confirmResult = await this.stripe.confirmCardPayment(paymentIntent.client_secret);

                if (confirmResult.error) {
                    console.warn('Failed to auth 3D secure:', confirmResult.error);
                    isOk = false;
                }
            }

            if (isOk) {
                await this.paymentService.completePaymentIntent(intentResponse.transaction_id).toPromise().catch(() => false);
            }

            if (isOk) {
                this.getAllLicensePlates();
                this.showSuccessPaymentMessage('profile.pbm.message_approved');
            } else {
                this.showSuccessPaymentMessage('profile.pbm.common_error');
            }
        });

        paymentRequest.on('cancel', async (e) => {
            await this.declinePBM(this.pbmData, 'wallet payment canceled');
            this.showSuccessPaymentMessage('profile.pbm.common_error');
            console.log('cancel', e);
        });

        this.walletPaymentAttrs = {paymentRequest};
        this.showPaymentPopup();
    }

    showPaymentPopup() {
        this.resetPaymentPopup();
        this.activePopup = 'payment';
    }

    async onSubmitPayment(makePayment: boolean) {
        if (this.paymentSubmittingBy) {
            return;
        }

        this.paymentSubmittingBy = makePayment ? 'yes' : 'no';

        if (!makePayment) {
            await this.declinePBM(this.pbmData, 'declined by user');
            this.showSuccessPaymentMessage('profile.pbm.message_declined');
            return;
        }

        const accountPaymentMethodType = this.paymentConfig.payment_method_type;
        switch (accountPaymentMethodType) {
            case 'DCB':
                this.payByCarrier();
                break;
            case 'PAYPAL':
                this.payByPayPal();
                break;
            case 'DEBIT_CARD':
            case 'CREDIT_CARD':
                this.payByCard();
                break;
            case 'GOOGLEPAY':
            case 'APPLEPAY':
                this.walletPaymentAttrs.paymentRequest.show();
                this.walletPaymentAttrs = null;
                break;
            case 'VENMO':
                this.payByVenmo();
                break;
        }
    }

    async payByVenmo() {
        const response = await this.sendPaymentRequest({
            pbmId: this.pbmData.pbm_id,
            makePayment: true,
        });

        if (!response || response.status !== 'OK') {
            await this.declinePBM(this.pbmData, 'failed to pay pbm with venmo');
            this.showPaymentError(response?.errorCode || API_RESPONSE_CODES.LICENSE_PLATE_REGISTERED_WITHOUT_PAYMENT);
            return;
        }

        this.showSuccessPaymentMessage('profile.pbm.message_approved');
    }

    validatePaymentPopup() {
        this.isPaymentPopupValid = (
            this.paymentConfig.payment_method_type !== 'DCB' ||
            !this.isPaymentZipRequired ||
            (this.zipCode || '').trim().length >= 4
        );
    }

    resetPaymentPopup() {
        this.zipCode = '';
        this.paymentSubmittingBy = null;
        this.validatePaymentPopup();
    }

    async onPaymentMethodSelected(event: PaymentMethodDoneEvent) {
        if (this.accountPaymentModel === 'POSTPAID') {
            this.executePBM(true);
        } else if (this.accountPaymentModel === 'FLEET') {
            if (event.isOk && (await this.paymentService.checkCurrentPaymentMethod(event.paymentConfig))) {
                this.executeFleetPayment();
            } else {
                this.showFleetResultMessage('profile.fleet.message_issues');
            }
        }
    }

    showPaymentMethodPopup() {
        this.activePopup = 'payment-method';
    }

    hidePaymentMethodPopup() {
        this.activePopup = null;
    }

    // ----------------------------------------

    private _allLicensePlates$: Subscription = null;

    private getAllLicensePlates() {
        this._allLicensePlates$ = this.licensePlatesService.getAllLicensePlates().subscribe(
            (res: AllLicensePlatesHttpResponse) => {
                if (res.plates === null && res.rental_plates === null) {
                    this.listOfLicensePlates = null;
                    this.listOfRentalLicensePlates = null;
                } else {
                    this.listOfLicensePlates = res.plates;
                    this.listOfRentalLicensePlates = this.sortLPNsBYActiveInactive(res.rental_plates);
                }
                this.setListState(this.listOfLicensePlates, this.listOfRentalLicensePlates);
            }
        );
    }

    private sortLPNsBYActiveInactive(list: LicensePlateItem[]) {
        const active = list.filter(lp => !this.isLicensePlateExpired(lp));
        const inactive = list.filter(lp => this.isLicensePlateExpired(lp));
        return [...active, ...inactive];
    }

    ngOnDestroy(): void {
        this.clearUpdateInterval();
        this.subs.forEach(sub => sub.unsubscribe());
        this._allLicensePlates$.unsubscribe();
    }

    setListState(listOfLicensePlates, listOfRentalLicensePlates) {
        if (listOfLicensePlates === null && listOfRentalLicensePlates === null) {
            this.listState = 'error';
        } else {
            const isListOfLicensePlatesNotEmpty = listOfLicensePlates && listOfLicensePlates.length > 0;
            const isListOfRentalLicensePlatesNotEmpty = listOfRentalLicensePlates && listOfRentalLicensePlates.length > 0;
            this.listState = !isListOfLicensePlatesNotEmpty && !isListOfRentalLicensePlatesNotEmpty ? 'empty' : 'list';
        }
    }

    onUnregisterClick(licensePlate: LicensePlateItem) {
        if (this.isLoading) {
            return;
        }

        this.lpToUnregister = licensePlate;
        this.activePopup = 'unregister-confirm';
    }

    hideUnregisterPopup() {
        this.activePopup = null;
        this.lpToUnregister = null;
    }

    onUnregisterCancel() {
        if (this.isLoading) {
            return;
        }

        this.hideUnregisterPopup();
    }

    async onUnregisterConfirm() {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        const lpnId = this.lpToUnregister.id;
        const errorCode: number = await this.licensePlatesService.unregLicensePlate(lpnId).toPromise().catch(e => e);

        this.isLoading = false;

        this.hideUnregisterPopup();

        switch (errorCode) {
            case 0:  // Ok
                this.listOfLicensePlates = this.listOfLicensePlates.filter(item => item.id !== lpnId);
                this.setListState(this.listOfLicensePlates, this.listOfRentalLicensePlates);

                this.toastService.create({
                    message: ['vehicles.unreg_ok'],
                    timeout: this._notification_message_timeout_MS
                });
                break;
            case 204:
                this.showErrorPopup(`vehicles.unreg_error_${errorCode}`);
                break;
            default:
                this.toastService.create({
                    message: ['vehicles.unreg_error'],
                    timeout: this._notification_message_timeout_MS
                });
                break;
        }
    }

    showErrorPopup(key: string, data?: any) {
        this.errorPopupMessage = {key, data};
        this.activePopup = 'error';
    }

    hideErrorPopup() {
        this.errorPopupMessage = null;
        this.activePopup = null;
    }

    onAddVehicle() {
        this.newLpnName = '';
        this.isLpnValid = false;
        this.addPopupError = null;
        this.activePopup = 'add';
    }

    onCloseAddPopup() {
        this.activePopup = null;
    }


    async submitNewLPNForm() {
        if (this.isLoading) {
            return;
        }

        this.addPopupError = null;
        this.isLoading = true;

        const licensePlate = (this.newLpnName || '').trim().replace(/\s+/g, ' ');

        this.lastLPN = licensePlate;

        let newLPN = {
            lp: licensePlate,
            rental: this.newLpnIsRentalCheckboxModel || false
        }

        const result = await this.licensePlatesService.addLicensePlate(newLPN).toPromise().catch(e => e);
        switch (result) {
            case 0 :
                this.isLoading = false;
                this.onCloseAddPopup();
                this.toastService.create({
                    message: ['vehicles.add_success'],
                    timeout: this._notification_message_timeout_MS
                });
                this.getAllLicensePlates();
                break;
            case API_RESPONSE_CODES.LICENSE_PLATE_REGISTRATION_ERROR:
            case API_RESPONSE_CODES.INVALID_LICENSE_PLATE_FORMAT:
            case API_RESPONSE_CODES.LICENSE_PLATE_REGISTRATION_WITH_ACCOUNT_LOCK:
            case API_RESPONSE_CODES.LICENSE_PLATE_REGISTRATION_WITH_PAYMENT_LOCK:
            case API_RESPONSE_CODES.LICENSE_PLATE_ASSIGNED_TO_ACCOUNT_ERROR:
            case API_RESPONSE_CODES.LICENSE_PLATE_BELONGS_TO_ANOTHER_FLEET:
            case API_RESPONSE_CODES.LICENSE_PLATE_ALREADY_REGISTERED:
                this.isLoading = false;
                this.addPopupError = {key: `vehicles.add_error_${result}`};
                break;
            case API_RESPONSE_CODES.LICENSE_PLATE_WITH_OUTSTANDING_PBM_INVOICE:
                await this.executePBM(false);
                this.isLoading = false;
                break;
            case API_RESPONSE_CODES.LICENSE_PLATE_REGISTRATION_FEE_REQUIRED:
                await this.executeFleetPayment();
                this.setIntervalUpdate();
                this.getAllLicensePlates();
                this.isLoading = false;
                this.newLpnIsRentalCheckboxModel = false;
                break;
            default:
                this.isLoading = false;
                this.addPopupError = {key: `vehicles.add_error_common`};
        }
    }

    validateLpn() {
        this.isLpnValid = !!(this.newLpnName || '').trim();
    }

    onGoBack() {
        this.router.navigateByUrl('/dashboard/profile');
    }

    clearUpdateInterval() {
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
            this.updateTimeout = null;
        }
    }

    setIntervalUpdate() {
        this.getAllLicensePlates();
        this.clearUpdateInterval();
        this.updateTimeout = setTimeout(() => this.setIntervalUpdate(), 30000);
    }

    // --------------------------------------------------------------------

    async executeFleetPayment() {
        this.attemptsToSelectPaymentMethodForFleet++;
        this.listOfPendingLPNs = await this.licensePlatesService.getPendingLPNs().toPromise().catch(() => null);


        if ((this.listOfPendingLPNs?.plates || []).length > 0) {
            this.isSelectedForPaymentPendingLPNByIdMap = this.listOfPendingLPNs.plates.reduce((acc, plate) => {
                acc[plate.id] = true;
                return acc;
            }, {});

            this.makeMapOfDatesForRentalPendingLPNs();

            this.listOfPendingLPNs.plates.forEach((plate) => {
                if (plate.rental) {
                    this.addControlToPendingLPNsForm(plate.id);
                }
            });
            this.recountTotalSumOfSelectedForPaymentPendingLPNs();
            this.activePopup = 'fleet-lpn';
        } else {
            this.listOfPendingLPNs = null;
            this.activePopup = null;
        }
    }

    recountTotalSumOfSelectedForPaymentPendingLPNs(selectedPlate?: PendingLPN) {
        this.addOrDeleteFormControlForSelectedRentalLPN(selectedPlate);
        if (this.listOfPendingLPNs) {
            this.selectedForPaymentPendingLPNsCount = Object.values(this.isSelectedForPaymentPendingLPNByIdMap).filter(isActive => isActive).length;
            this.activePendingLPNsTotal = this.listOfPendingLPNs.fee * this.selectedForPaymentPendingLPNsCount;
        } else {
            this.selectedForPaymentPendingLPNsCount = 0;
            this.activePendingLPNsTotal = 0;
        }
    }

    private addOrDeleteFormControlForSelectedRentalLPN(selectedPlate: PendingLPN) {
        if (selectedPlate && selectedPlate.rental) {
            const selectedLPNFormControl = this.pendingLPNsForm.get(selectedPlate.id);
            if (selectedLPNFormControl) {
                this.formValidatorService.deleteAllFormControlsFromFormGroup(this.pendingLPNsForm.get(selectedPlate.id) as FormGroup);
                this.formValidatorService.deleteFormControlFromFormGroup(selectedPlate.id, this.pendingLPNsForm);
            } else {
                this.addControlToPendingLPNsForm(selectedPlate.id);
            }
        }
    }

    async onConfirmFleetPayment() {

        if (this.pendingLPNsForm.invalid) {
            this.formValidatorService.validateFormGroup(this.pendingLPNsForm);
        } else {

            if (this.isConfirmingFleet || !this.listOfPendingLPNs) {
                return;
            }

            this.isConfirmingFleet = true;

            this.paymentConfig = await this.paymentService.fetchPaymentConfig().toPromise().catch(() => null);

            if (!this.paymentConfig) {
                this.isConfirmingFleet = false;
                this.listOfPendingLPNs = null;
                this.activePopup = null;
                this.showFleetResultMessage('profile.fleet.message_issues');
                return;
            }

            const isPaymentMethodOk = await this.paymentService.checkCurrentPaymentMethod(this.paymentConfig, null, {
                amount: this.activePendingLPNsTotal,
                currency: 'USD'
            }).catch(() => false);

            if (!isPaymentMethodOk) {
                this.isConfirmingFleet = false;
                this.listOfPendingLPNs = null;

                if (this.attemptsToSelectPaymentMethodForFleet <= 1) {
                    this.showPaymentMethodPopup();
                } else {
                    this.showFleetResultMessage('profile.fleet.message_issues');
                }

                return;
            }

            const listOfSelectedLPNs: {
                pending_license_plate_id: string,
                end_date?: Date | string | null
            }[] = [];

            const listOfFullTitlesOfSelectedLPNs: string[] = [];

            this.listOfPendingLPNs.plates.forEach((plate) => {
                if (this.isSelectedForPaymentPendingLPNByIdMap[plate.id]) {
                    listOfSelectedLPNs.push(
                        {
                            pending_license_plate_id: plate.id,
                            end_date: plate.rental ? this.getEndDateForRentalLPN(plate) : null
                        });
                    const lpnFullTitle = [plate.lps, plate.lpn].join(' ').trim();
                    listOfFullTitlesOfSelectedLPNs.push(`<strong>${lpnFullTitle}</strong>`);
                }
            });

            await this.getInvoicesForPendingLPNs(listOfSelectedLPNs);
            this.makePaymentByType(this.paymentConfig.payment_method_type);
        }
    }

    private async getInvoicesForPendingLPNs(listOfSelectedLPNs: { pending_license_plate_id: string; end_date?: Date | string | null }[]) {
        this.pendingLPNsInvoice = await this.licensePlatesService.acceptPendingLPNsWithRental(listOfSelectedLPNs).toPromise().catch(() => null);

        const isInvoiceEmpty = !this.pendingLPNsInvoice || !this.pendingLPNsInvoice.invoice_id || !this.pendingLPNsInvoice.invoice_items;
        if (isInvoiceEmpty) {
            this.cancelOfAddingNewLPNs();
            return;
        }
    }

    private cancelOfAddingNewLPNs() {
        this.isConfirmingFleet = false;
        this.listOfPendingLPNs = null;
        this.showFleetResultMessage('dashboard.fleet.message_declined');
    }

    private makePaymentByType(type: PaymentMethodType) {
        switch (type) {
            case 'DCB':
                if (this.paymentConfig.payment_verification_required) {
                    this.showFleetZipPopup();
                } else {
                    this.submitFleetZipPayment();
                }
                break;
            case 'PAYPAL':
                this.payByPayPalFleet();
                break;
            case 'DEBIT_CARD':
            case 'CREDIT_CARD':
                this.payByCardFleet();
                break;
            case 'GOOGLEPAY':
            case 'APPLEPAY':
                this.payByWalletFleet();
                break;
            case 'VENMO':
                this.payByVenmoFleet();
                break;
        }
    }

    private makeMapOfDatesForRentalPendingLPNs() {
        this.mapOfDatesForRentalPendingLPNs = this.listOfPendingLPNs.plates.reduce(
            (acc, plate) => {
                if (plate.rental) {
                    acc[plate.id] = {end_date: ''};
                }
                return acc;
            }, {});
    }

    async payByVenmoFleet() {
        const {response, errorCode} = await this.invoicesService.makePayment({
            invoices: this.getInvoiceForFleetPayment(),
            verification_code: null,
            payment_method_type: this.paymentConfig.payment_method_type,
            payment_method_id: this.paymentConfig.payment_method_id,
            return_url: null,
            cancel_url: null,
        }).toPromise().catch(error => error);

        this.isConfirmingFleet = false;

        if (!errorCode && response.payment_complete) {
            this.newLPNAddedSusses();
        } else {
            this.showFleetResultMessage('profile.fleet.message_issues');
        }
    }

    private newLPNAddedSusses() {
        this.showFleetResultMessage('profile.fleet.message_ok');
        this.getAllLicensePlates();
    }

    getInvoiceForFleetPayment(): InvoicePaymentInvoice[] {
        return [
            {
                invoice_id: this.pendingLPNsInvoice.invoice_id,
                items: this.pendingLPNsInvoice.invoice_items.map(itemId => ({
                    item_id: itemId,
                    approved: true,
                    dispute_type: null
                }))
            }
        ];
    }

    async payByPayPalFleet() {
        const successResultData = this.encodeJsonToUrl({
            messageKey: 'profile.fleet.message_ok',
            messageData: this.paymentResultMessageKey,
        });

        const cancelResultData = this.encodeJsonToUrl({
            messageKey: 'profile.fleet.message_issues',
            messageData: this.paymentResultMessageKey,
        });

        const {response, errorCode} = <InvoicePaymentResponseWithError>await this.invoicesService.makePayment({
            invoices: this.getInvoiceForFleetPayment(),
            verification_code: null,
            payment_method_type: this.paymentConfig.payment_method_type,
            payment_method_id: this.paymentConfig.payment_method_id,
            return_url: `/dashboard/profile/vehicles?action=veh_fleet_lpn_ppp_complete&veh_fleet_lpn_ppp_result=${successResultData}`,
            cancel_url: `/dashboard/profile/vehicles?action=veh_fleet_lpn_ppp_cancel&veh_fleet_lpn_ppp_result=${cancelResultData}`,
        }).toPromise().catch(error => error);

        const approveUrl = response?.payment_intent?.approve_payment_url;

        if (errorCode || !approveUrl) {
            this.isConfirmingFleet = false;
            this.showFleetResultMessage('profile.fleet.message_issues');
        } else {
            window.location.assign(approveUrl);
        }
    }

    async payByCardFleet() {
        const {response: intentResponse, errorCode} = await this.invoicesService.makePayment({
            invoices: this.getInvoiceForFleetPayment(),
            verification_code: null,
            payment_method_type: this.paymentConfig.payment_method_type,
            payment_method_id: this.paymentConfig.payment_method_id,
            return_url: null,
            cancel_url: null,
        }).toPromise().catch(error => error);

        if (errorCode) {
            this.isConfirmingFleet = false;
            this.showFleetResultMessage('profile.fleet.message_issues');
            return;
        }

        const paymentIntent = intentResponse.payment_intent;

        if (intentResponse.payment_complete) {
            this.isConfirmingFleet = false;
            this.newLPNAddedSusses();
            return;
        }

        // Payment incomplete
        // -------------------------------------------------------

        const response = await this.stripe.confirmCardPayment(paymentIntent.client_secret).catch(() => null);

        if (!response) {
            this.isConfirmingFleet = false;
            this.showFleetResultMessage('profile.fleet.message_issues');
            return;
        }

        if (response.error) {
            this.isConfirmingFleet = false;
            const error = this.stripeService.localizeStripeError(response.error);
            this.showFleetResultMessage('profile.fleet.message_issues');
            console.warn('Failed to auth 3D secure:', response, error);
            return;
        }

        const isOkOrError = await this.paymentService.completePaymentIntent(intentResponse.transaction_id).toPromise().catch(errorCode => errorCode);

        this.isConfirmingFleet = false;

        if (isOkOrError === true) {
            this.newLPNAddedSusses();
        } else {
            this.showFleetResultMessage('profile.fleet.message_issues');
        }
    }

    resetFleetZipPopup() {
        this.zipCode = '';
        this.isFleetZipSubmitting = false;
        this.validateFleetZipPopup();
    }

    showFleetZipPopup() {
        this.isConfirmingFleet = false;
        this.activePopup = 'fleet-lpn-zip';
    }

    validateFleetZipPopup() {
        this.isFleetZipValid = (this.zipCode || '').trim().length >= 4;
    }

    async submitFleetZipPayment() {
        if (!this.isFleetZipValid || this.isFleetZipSubmitting) {
            return;
        }

        this.isFleetZipSubmitting = true;

        const {errorCode} = await this.invoicesService.makePayment({
            invoices: this.getInvoiceForFleetPayment(),
            verification_code: this.paymentConfig.payment_verification_required ? (this.zipCode || '').trim() : null,
            payment_method_type: this.paymentConfig.payment_method_type,
            payment_method_id: this.paymentConfig.payment_method_id,
            return_url: null,
            cancel_url: null,
        }).toPromise().catch(error => error);

        this.isFleetZipSubmitting = false;

        if (!errorCode) {
            this.newLPNAddedSusses();
        } else {
            this.showFleetResultMessage('profile.fleet.message_issues');
        }
    }

    async payByWalletFleet() {
        const walletType = <PaymentMethodWallet>this.paymentConfig.payment_method_type;

        const {response: intentResponse, errorCode} = await this.invoicesService.makePayment({
            invoices: this.getInvoiceForFleetPayment(),
            verification_code: null,
            payment_method_type: walletType,
            payment_method_id: this.paymentConfig.payment_method_id,
            return_url: null,
            cancel_url: null,
        }).toPromise().catch(error => error);

        if (errorCode) {
            this.isConfirmingFleet = false;
            this.showFleetResultMessage('profile.fleet.message_issues');
            return;
        }

        const paymentIntent = intentResponse.payment_intent;

        const {paymentRequest} = await this.stripeService.getWalletPaymentRequest({
            amount: paymentIntent.amount,
            currency: paymentIntent.currency,
            label: 'Total',
        });

        if (!paymentRequest) {
            this.isConfirmingFleet = false;
            this.showFleetResultMessage('profile.fleet.message_issues');
            return;
        }

        paymentRequest.on('paymentmethod', async (e) => {
            const confirmResponse = await this.stripe.confirmCardPayment(paymentIntent.client_secret, {
                payment_method: e.paymentMethod.id
            }, {
                handleActions: false
            });

            if (confirmResponse.error) {
                e.complete('fail');
                this.isConfirmingFleet = false;
                const error = this.stripeService.localizeStripeError(confirmResponse.error);
                this.showFleetResultMessage('profile.fleet.message_issues');
                console.warn('Failed:', error);
                return;
            }

            e.complete('success');

            let isOk = true;

            if (confirmResponse.paymentIntent.status === 'requires_action') {
                const confirmResult = await this.stripe.confirmCardPayment(paymentIntent.client_secret);

                if (confirmResult.error) {
                    console.warn('Failed to auth 3D secure:', confirmResult.error);

                    if (confirmResponse.error.code === 'card_declined') {
                        this.isConfirmingFleet = false;
                        const error = this.stripeService.localizeStripeError(confirmResult.error);
                        this.showFleetResultMessage('profile.fleet.message_issues');
                        console.warn('Failed:', error);
                        return;
                    }

                    isOk = false;
                }
            }

            if (isOk) {
                await this.paymentService.completePaymentIntent(intentResponse.transaction_id).toPromise().catch(() => false);
            }

            this.isConfirmingFleet = false;

            if (isOk) {
                this.newLPNAddedSusses();
            } else {
                this.showFleetResultMessage('profile.fleet.message_issues');
            }
        });

        paymentRequest.on('cancel', () => {
            this.isConfirmingFleet = false;
            this.showFleetResultMessage('profile.fleet.message_issues');
        });

        if (this.stripeService.isSyncPaymentRequest()) {
            this.onShowWalletPaymentConfirmPopup(paymentRequest, paymentIntent, walletType);
        } else {
            paymentRequest.show();
        }
    }

    onShowWalletPaymentConfirmPopup(paymentRequest: any, paymentIntent: any, walletType: PaymentMethodWallet) {
        this.isConfirmingFleet = false;
        this.isFleetWalletSubmitting = false;

        this.walletFleetPaymentAttrs = {
            amountFormatted: this.currencyService.format(paymentIntent.amount, paymentIntent.currency),
            wallet: this.stripeService.getWalletName(walletType),
            paymentRequest
        };

        this.activePopup = 'fleet-wallet-payment-confirm';
    }

    onConfirmFleetWalletPayment() {
        this.walletFleetPaymentAttrs.paymentRequest.show();
    }

    showFleetResultMessage(messageKey: string) {
        this.paymentResultMessageKey = messageKey;
        this.activePopup = 'payment-result';
    }


    public onCancelExtendRentalPeriodPopup() {
        this.activePopup = null;
        this.selectedLPNForExtend = null;
        this.extendLPNPeriodForm.patchValue({end_date: null});
        this.extendLPNPeriodForm.patchValue({end_time: null});
    }


    public onSubmitExtendRentalPeriodPopup() {
        const formValue = this.extendLPNPeriodForm.value;

        if (this.extendLPNPeriodForm.valid) {
            const endDate = new Date(formValue.end_date);
            const endTime = new Date(formValue.end_time);

            const endDateTime = this.getMixedDateTimeFromTwoDates(endDate, endTime);

            this.activePopup = null;

            this.extendRentalPeriod(endDateTime.toISOString());
        } else {
            this.formValidationService.validateFormGroup(this.extendLPNPeriodForm);
        }
    }

    private getMixedDateTimeFromTwoDates(dateForDate: Date, DateForTime: Date) {
        const mixedDate = new Date(
            dateForDate.getFullYear(),
            dateForDate.getMonth(),
            dateForDate.getDate(),
            DateForTime.getHours(),
            DateForTime.getMinutes()
        );
        return mixedDate || null;
    }


    onExtendLPNRentalPeriod(licensePlate: LicensePlateItem) {
        this.selectedLPNForExtend = licensePlate;
        this.extendLPNPeriodForm.patchValue({end_date: this.getDateFromDateTime(licensePlate.end_date)});
        this.extendLPNPeriodForm.patchValue({end_time: this.getTimeFromDateTime(licensePlate.end_date)});
        const endDateControl = this.extendLPNPeriodForm.get('end_date');
        const endTimeControl = this.extendLPNPeriodForm.get('end_time');

        endDateControl.setValidators([Validators.required, minDateValidator(this.getDateFromDateTime(licensePlate.end_date))]);
        endTimeControl.setValidators([Validators.required, minOneHourExtendDurationValidator(this.getDateFromDateTime(licensePlate.end_date), this.getTimeFromDateTime(licensePlate.end_date))]);

        this.activePopup = 'extend-rental-lpn-period';
    }

    private getDateFromDateTime(dateTime: string): Date {
        const date = new Date(dateTime);
        date.setHours(0, 0, 0, 0);
        return date;
    }

    private getTimeFromDateTime(dateTime: string): Date {
        const date = new Date(dateTime);
        date.setFullYear(1970, 0, 1);
        return date;
    }


    private extendRentalPeriod(endDateTime: string) {
        this.licensePlatesService.extendLPNRentalPeriod(this.selectedLPNForExtend, endDateTime).subscribe(
            async (_res) => {
                this.toastService.create({
                    message: ['vehicles.rental_period_extend_susses'],
                    timeout: this._notification_message_timeout_MS
                });
                this.getAllLicensePlates();
            },
            (_err) => {

                this.toastService.create({
                    message: ['vehicles.rental_period_extend_error'],
                    timeout: this._notification_message_timeout_MS
                });
            }
        )
    }

    isLicensePlateExpired(licensePlate: LicensePlateItem) {
        return licensePlate.end_date && new Date(licensePlate.end_date) < new Date();
    }

    private getEndDateForRentalLPN(plate: PendingLPN): Date {
        const formValue = this.pendingLPNsForm.value?.[plate.id];
        const endDate = new Date(formValue?.endDate);
        const endTime = new Date(formValue?.endTime);

        return this.getMixedDateTimeFromTwoDates(endDate, endTime);
    }

    public getControl(controlName, formGroup: FormGroup = this.extendLPNPeriodForm): AbstractControl | null {
        return this.formValidatorService.getFormControl(controlName, formGroup);
    }
}
