import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PendingLPN} from "../../services/license-plates.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {FormValidationService} from "../../_shared/validation-errors/form-validation.service";

const startAnimationStyles = {height: '100px', opacity: 1};
const endBlockStyles = {height: 0, opacity: 0};
const inOutAnimationDuration: string = '0.3s';

const enterAnimationType = ' ease-out';
const outAnimationType = ' ease-in';

@Component({
    selector: 'app-lpn-card',
    templateUrl: './lpn-card.component.html',
    styleUrls: ['./lpn-card.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style(endBlockStyles),
                        animate(inOutAnimationDuration + enterAnimationType,
                            style(startAnimationStyles))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style(startAnimationStyles),
                        animate(inOutAnimationDuration + outAnimationType,
                            style(endBlockStyles))
                    ]
                )
            ]
        )
    ],
})
export class LpnCardComponent implements OnInit {

    @Input() pendingLPNsFee;
    @Input() plate: PendingLPN;
    @Input() isDisabledToSelect: boolean = false;
    @Output() onSelectedLPN = new EventEmitter<any>();

    @Input() selectModel;
    @Input() datesModel;
    @Input() form: FormGroup;

    constructor(private formValidatorService: FormValidationService) {

    }

    ngOnInit(): void {
    }

    public emmitSelectedLPN(plate: PendingLPN) {
        this.onSelectedLPN.emit(plate);
    }

    public getField(controlName: string, parentFormGroupName: string): AbstractControl {
        const parentFormGroup = this.form.get(parentFormGroupName) as FormGroup;
        return this.formValidatorService.getFormControl(controlName, parentFormGroup);
    }

}
