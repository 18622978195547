<div class="dashboard__sidebar-nest" *ngIf="viewportBreakpoint === 'desktop'">
    <app-dashboard-sidebar
        [isCoverageVisible]="isCoverageVisible"></app-dashboard-sidebar>
</div>
<div class="dashboard__content">
    <router-outlet></router-outlet>
</div>

<!-- Popups -->
<popup-custom class="dashboard__payment-popup"
              *ngIf="isActiveModal('payment')"
              [isDisabled]="paymentSubmittingBy !== null">
    <popup-content>
        <div [innerHTML]="payByMailConfirmMessageKey | translate : welcomeMessageData"></div>
        <input type="text" class="input input_large dashboard__payment-popup-zip"
               *ngIf="paymentConfig.payment_method_type === 'DCB' && isPaymentZipRequired"
               [(ngModel)]="zipCode"
               [placeholder]="'dashboard.payment.zip_code' | translate"
               (ngModelChange)="validatePaymentPopup()"
        >
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_light-blue"
                (click)="onSubmitPayment(false)"
                [isDisabled]="paymentSubmittingBy !== null"
                [isProgress]="paymentSubmittingBy === 'no'"
        >
            <span class="button__caption">{{ 'dashboard.payment.no' | translate }}</span>
        </button>
        <button type="button" class="button button_regular button_blue"
                (click)="onSubmitPayment(true)"
                [isDisabled]="!isPaymentPopupValid || paymentSubmittingBy !== null"
                [isProgress]="paymentSubmittingBy === 'yes'"
        >
            <span class="button__caption">{{ 'dashboard.payment.yes' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="dashboard__welcome-popup"
              *ngIf="isActiveModal('welcome')">
    <popup-content>
        <span [innerHTML]="welcomeMessageKey | translate : welcomeMessageData"></span>
    </popup-content>
    <popup-controls>
        <button type="button" class="button button_regular button_blue" (click)="hideWelcomeMessage()">
            <span class="button__caption">{{ 'dashboard.welcome.ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="dashboard__fleet-lpn-popup"
              *ngIf="isActiveModal('fleet-lpn')"
              [isDisabled]="isConfirmingFleet">
    <popup-content>
        <div class="dashboard__fleet-lpn-popup-header">
            {{ 'dashboard.fleet.header_message' | translate }}
        </div>
        <ng-container *ngFor="let plate of listOfPendingLPNs.plates">
            <app-lpn-card [pendingLPNsFee]="listOfPendingLPNs.fee"
                          [plate]="plate"
                          [selectModel]="isSelectedForPaymentPendingLPNByIdMap"
                          [datesModel]="mapOfDatesForRentalPendingLPNs[plate.id]"
                          [form]="pendingLPNsForm"
                          (onSelectedLPN)="recountTotalSumOfSelectedForPaymentPendingLPNs($event)">
            </app-lpn-card>
        </ng-container>
    </popup-content>
    <popup-controls>
        <button type="button" class="button button_regular button_blue"
                [isProgress]="isConfirmingFleet"
                [isDisabled]="isConfirmingFleet"
                (click)="onConfirmFleetPayment()"
        >
            <span *ngIf="selectedForPaymentPendingLPNsCount < 1" class="button__caption">
                {{ 'vehicles.add_vehicle_cancel' | translate }}
            </span>
            <span *ngIf="selectedForPaymentPendingLPNsCount > 0" class="button__caption">
                {{ 'dashboard.fleet.pay_button' | translate : {amount: (selectedForPaymentPendingLPNsTotalSum | currency : 'USD')} }}
            </span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="dashboard__fleet-lpn-zip-popup"
              *ngIf="isActiveModal('fleet-lpn-zip')"
              [isDisabled]="isFleetZipSubmitting">
    <popup-content>
        <input type="text" class="input input_large dashboard__fleet-lpn-zip-popup-zip"
               [(ngModel)]="zipCode"
               [placeholder]="'dashboard.fleet.zip_code' | translate"
               (ngModelChange)="validateFleetZipPopup()"
        >
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_blue"
                (click)="submitFleetZipPayment()"
                [isDisabled]="!isFleetZipValid"
                [isProgress]="isFleetZipSubmitting"
        >
            <span class="button__caption">{{ 'dashboard.fleet.zip_pay' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="dashboard__fleet-lpn-wallet-popup"
              *ngIf="isActiveModal('fleet-wallet-payment-confirm')"
              [isDisabled]="isFleetWalletSubmitting"
>
    <popup-content>
        <span
            [innerHTML]="'dashboard.fleet.wallet_confirm_message' | translate : { amount: walletFleetPaymentAttrs.amountFormatted, wallet: walletFleetPaymentAttrs.wallet }"></span>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_blue" [isProgress]="isFleetWalletSubmitting"
                (click)="onConfirmFleetWalletPayment()">
            <span class="button__caption">{{ 'dashboard.fleet.wallet_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="dashboard__debt-lock-popup"
              *ngIf="isActiveModal('account-debt-lock')">
    <popup-content>
        <span [innerHTML]="'debt_lock.welcome_message' | translate"></span>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_blue" (click)="onPayDebt()">
            <span class="button__caption">{{ 'debt_lock.pay_debt_button' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<payment-method
    *ngIf="isActiveModal('payment-method')"
    [mode]="paymentMethodPopupMode"
    [hideCancelButton]="true"
    [isSilentInit]="true"
    (onDone)="onPaymentMethodSelected($event)"
></payment-method>
