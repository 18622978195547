import {Injectable} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class FormValidationService {

    constructor() {
    }

    public getFormControl(controlName: string, parentFormGroup: FormGroup): AbstractControl {
        if (parentFormGroup) {
            const field = parentFormGroup.get(controlName);
            return field ? field : new FormControl('');
        }
        return new FormControl('');
    }


    private makeFormGroupTouched(formGroup: FormGroup): void {
        if (formGroup) {
            formGroup.markAllAsTouched();
        }
    }

    public validateFormGroup(formGroup: FormGroup): void {
        if (formGroup) {
            this.makeFormGroupTouched(formGroup);
            this.markFormDirty(formGroup);
        }
    }

    private markFormDirty(form: FormGroup): void {
        this.markGroupDirty(form);
    }

    private markGroupDirty(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(key => {
            switch (formGroup.get(key).constructor.name) {
                case "FormGroup":
                    this.markGroupDirty(formGroup.get(key) as FormGroup);
                    break;
                case "FormArray":
                    this.markArrayDirty(formGroup.get(key) as FormArray);
                    break;
                case "FormControl":
                    this.markControlDirty(formGroup.get(key) as FormControl);
                    break;
            }
        });
    }

    private markArrayDirty(formArray: FormArray) {
        formArray.controls.forEach(control => {
            switch (control.constructor.name) {
                case "FormGroup":
                    this.markGroupDirty(control as FormGroup);
                    break;
                case "FormArray":
                    this.markArrayDirty(control as FormArray);
                    break;
                case "FormControl":
                    this.markControlDirty(control as FormControl);
                    break;
            }
        });
    }

    private markControlDirty(formControl: FormControl) {
        formControl.markAsDirty();
    }

    public deleteFormControlFromFormGroup(controlName: string, formGroup: FormGroup): void {
        if (formGroup) {
            formGroup.removeControl(controlName);
        }
    }

    public deleteAllFormControlsFromFormGroup(formGroup: FormGroup): void {
        if (formGroup) {
            const formControls = formGroup.controls;
            Object.keys(formControls).forEach(key => {
                formGroup.removeControl(key);
            })
        }
    }

}
