<app-bar *ngIf="viewportBreakpoint !== 'desktop'"></app-bar>

<page-panel *ngIf="viewportBreakpoint === 'desktop'">
    <h1 class="page-panel__header">{{ 'profile.page_header' | translate }}</h1>
</page-panel>

<div class="profile__content">
    <h1 class="page-header" *ngIf="viewportBreakpoint !== 'desktop'"
        [innerHTML]="'profile.page_header' | translate"></h1>
    <app-profile-nav [isDebtLock]="isDebtLock"
                     (OnDeactivateAccount)="onDeactivateAccount()"
                     (OnShowPaymentMethodPopup)="onShowPaymentMethodPopup()"></app-profile-nav>
</div>

<!-- Popups -->
<ng-container [ngSwitch]="activePopup">
    <popup-custom class="profile__deactivate-account-popup" *ngSwitchCase="'deactivate-account'"
                  [isDisabled]="isDeactivateAccountSubmitting">
        <popup-content>
            <span [innerHTML]="'profile.confirm_deactivate_account' | translate"></span>
        </popup-content>
        <popup-controls [isStretched]="true">
            <button type="button" class="button button_regular button_light-blue" (click)="onCloseDeactivatePopup()">
                <span class="button__caption">{{ 'profile.confirm_deactivate_account_cancel' | translate }}</span>
            </button>
            <button type="button" class="button button_regular button_blue" (click)="onConfirmDeactivateAccount()"
                    [isProgress]="isDeactivateAccountSubmitting">
                <span class="button__caption">{{ 'profile.confirm_deactivate_account_ok' | translate }}</span>
            </button>
        </popup-controls>
    </popup-custom>

    <popup-custom class="profile__deactivate-account-result-popup" *ngSwitchCase="'deactivate-account-result'">
        <popup-content>
            <span [innerHTML]="deactivateAccountResultMsgKey | translate : deactivateAccountResultMsgData"></span>
        </popup-content>
        <popup-controls [isStretched]="true">
            <button type="button" class="button button_regular button_blue" (click)="onCloseDeactivateResultPopup()">
                <span class="button__caption">{{ 'profile.deactivate_account_result_ok' | translate }}</span>
            </button>
        </popup-controls>
    </popup-custom>

    <payment-method
        *ngSwitchCase="'payment-method'"
        [mode]="'edit'"
        [isSilentInit]="false"
        (onDone)="onClosePaymentMethodPopup()"
    ></payment-method>
</ng-container>