import {Component, Input, OnInit} from '@angular/core';
import {DeviceService} from "../../services/device.service";

@Component({
    selector: 'app-dashboard-sidebar',
    templateUrl: './dashboard-sidebar.component.html',
    styleUrls: ['./dashboard-sidebar.component.scss']
})
export class DashboardSidebarComponent implements OnInit {

    readonly sidebarScrollAreaMarginRight: number = -17;

    @Input() isCoverageVisible: () => boolean;
    currentYear: any;

    constructor(private deviceService: DeviceService) {
        this.sidebarScrollAreaMarginRight = -1 * this.deviceService.getScrollbarWidth();
        this.currentYear = new Date().getFullYear();
    }

    ngOnInit(): void {

    }

}
