<ng-container [ngSwitch]="viewportBreakpoint">
    <page-panel *ngSwitchCase="'desktop'">
        <h1 class="page-panel__header">{{ 'coverage.page_header' | translate }}</h1>
    </page-panel>
    <app-bar *ngSwitchDefault>
        <button type="button" class="app-bar__button" (click)="onSidebarStateChange(true)">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
                <path style="fill: currentColor;" d="M9,21.32l-.65-.56C8.05,20.5,1,14.39,1,8A8,8,0,0,1,17,8c0,6.39-7,12.5-7.35,12.76ZM9,2A6,6,0,0,0,3,8c0,4.43,4.32,9,6,10.64C10.67,17,15,12.43,15,8A6,6,0,0,0,9,2ZM9,12a4,4,0,1,1,4-4A4,4,0,0,1,9,12ZM9,6a2,2,0,1,0,2,2A2,2,0,0,0,9,6Z"/>
            </svg>
        </button>
    </app-bar>
</ng-container>

<ng-template #uiTpl>
    <div class="coverage__ui" *ngIf="state === 'ready'">
        <div class="coverage__ui-search">
            <div class="coverage__ui-search-wrap" [class.coverage__ui-search-wrap_has-clear]="isClearVisible">
                <input type="text" class="input input_regular coverage__ui-search-input"
                    #searchInputEl
                    [placeholder]="'coverage.search_placeholder' | translate"
                    [(ngModel)]="searchValue"
                    (ngModelChange)="onSearch()"
                    (keydown)="onSearchKeyDown($event)"
                >
                <div class="coverage__ui-search-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M15.89,14.48a6,6,0,1,0-1.41,1.41L19,20.36,20.36,19ZM11,15a4,4,0,1,1,4-4,3.92,3.92,0,0,1-.56,2A4,4,0,0,1,13,14.44,3.92,3.92,0,0,1,11,15Z"/>
                    </svg>
                </div>
                <button type="button" class="coverage__ui-search-clear" (click)="onClearSearchClick()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <polygon points="13.42 12 17.78 16.36 16.36 17.78 12 13.42 7.64 17.78 6.22 16.36 10.58 12 6.22 7.64 7.64 6.22 12 10.58 16.36 6.22 17.78 7.64 13.42 12" style="fill: currentColor;"></polygon>
                    </svg>
                </button>
            </div>
        </div>
        <ng-container [ngSwitch]="visibleCoverageRoutes.length">
            <div class="coverage__ui-message" *ngSwitchCase="0">
                {{ 'coverage.no_routes' | translate }}
            </div>
            <div class="coverage__ui-scroll" *ngSwitchDefault>
                <div class="coverage__routes">
                    <div class="coverage__route"
                        *ngFor="let route of visibleCoverageRoutes; trackBy: trackRouteBy"
                        [attr.data-route-id]="route.id"
                        [class.coverage__route_no-gantries]="route.gantries.length === 0"
                        [class.coverage__route_active]="activeRouteId === route.id"
                        [class.coverage__route_expanded]="route.isExpanded"
                    >
                        <div class="coverage__route-heading">
                            <div class="coverage__route-heading-primary" (click)="onRouteExpandClick(route)">
                                <div class="coverage__route-heading-triangle">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                        <path style="fill: currentColor;" d="M13.07,6.67,11.66,5.26,8,8.91,4.34,5.26,2.93,6.67l3.66,3.66h0L8,11.74l1.41-1.41h0Z"></path>
                                    </svg>
                                </div>
                                <div class="coverage__route-heading-title" [innerHTML]="route.name"></div>
                            </div>
                            <div class="coverage__route-heading-eye" (click)="onRouteEyeClick(route)" [title]="'coverage.show_on_map_tt' | translate">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,10Zm0,10C4.43,20,1.21,12.7,1.08,12.39L.91,12l.17-.39C1.21,11.3,4.43,4,12,4s10.79,7.3,10.92,7.61l.17.39-.17.39C22.79,12.7,19.57,20,12,20ZM3.11,12c.72,1.4,3.49,6,8.89,6s8.17-4.59,8.89-6C20.17,10.59,17.4,6,12,6S3.83,10.59,3.11,12Z"/>
                                </svg>
                            </div>
                        </div>
                        <div class="coverage__route-content" *ngIf="route.isExpanded" [@expand]>
                            <div class="coverage__gantries">
                                <div class="coverage__gantry"
                                    *ngFor="let gantry of route.gantries; trackBy: trackGantryBy"
                                    [attr.data-gantry-id]="gantry.id"
                                    [class.coverage__gantry_active]="activeGantryId === gantry.id"
                                    (click)="onToggleGantry(route, gantry)"
                                >
                                    <div class="coverage__gantry-content">
                                        <div class="coverage__gantry-name" [innerHTML]="gantry.name"></div>
                                        <div class="coverage__gantry-code" [innerHTML]="gantry.code"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<div class="coverage__content" [ngSwitch]="state === 'error'">
    <div class="coverage__error" *ngSwitchCase="true">
        {{ 'coverage.common_error' | translate }}
    </div>
    <ng-container *ngSwitchCase="false">
        <div class="coverage__nest">
            <div class="coverage__map" #mapEl></div>
            <div class="coverage__panel" *ngIf="viewportBreakpoint === 'desktop'">
                <ng-container *ngTemplateOutlet="uiTpl"></ng-container>
            </div>
        </div>
        <div class="coverage__loading" *ngIf="state === 'loading'">
            <loader></loader>
        </div>
    </ng-container>
</div>

<div class="coverage__sidebar" *ngIf="isSidebarActive" [@sidebar]>
    <div class="coverage__sidebar-overlay" [@sidebarOverlay] (click)="onSidebarStateChange(false)"></div>
    <div class="coverage__sidebar-popup" [@sidebarPopup]>
        <div class="coverage__sidebar-popup-header" (click)="onSidebarStateChange(false)">
            <div class="coverage__sidebar-popup-header-title">{{ 'coverage.sidebar_header' | translate }}</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="coverage__sidebar-popup-header-icon">
                <path fill="currentColor" d="M11.42,10l6.36,6.36-1.42,1.42L10,11.42,3.64,17.78,2.22,16.36,8.58,10,2.22,3.64,3.64,2.22,10,8.58l6.36-6.36,1.42,1.42Z"/>
            </svg>
        </div>
        <ng-container *ngTemplateOutlet="uiTpl"></ng-container>
    </div>
</div>
