import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-profile-nav',
    templateUrl: './profile-nav.component.html',
    styleUrls: ['./profile-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileNavComponent implements OnInit {

    @Input() isDebtLock: boolean;
    @Output() OnShowPaymentMethodPopup = new EventEmitter();
    @Output() OnDeactivateAccount = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

    public emmitShowPaymentMethodPopup(): void {
        this.OnShowPaymentMethodPopup.emit();
    }

    public emmitDeactivateAccount(): void {
        this.OnDeactivateAccount.emit();
    }

}
