import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LicensePlateItem} from "../../../../services/license-plates.service";

@Component({
    selector: 'app-lpns-list',
    templateUrl: './lpns-list.component.html',
    styleUrls: ['./lpns-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LpnsListComponent implements OnInit {

    @Input() lpnsList: LicensePlateItem[];
    @Input() listTitle: string = '';
    @Input() isRental: boolean = false;
    @Output() onLpnSelectedToUnregister: any = new EventEmitter();
    @Output() onLpnSelectedToRentalExtend: any = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

    onUnregisterClick(licensePlate: LicensePlateItem) {
        this.onLpnSelectedToUnregister.emit(licensePlate);
    }

    onRentalExtendClick(licensePlate: LicensePlateItem) {
        this.onLpnSelectedToRentalExtend.emit(licensePlate);
    }

    isLicensePlateExpired(LicensePlate: LicensePlateItem) {
        return LicensePlate.end_date && new Date(LicensePlate.end_date) < new Date();
    }
}
